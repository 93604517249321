import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { TextField, Button } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { invokeApi, invokeUploadFile } from "./api/index";
import {
  API_URL,
  MOVE_REQUEST,
  UPLOAD,
  API_KEY,
  UPDATE_MOVE,
} from "./api/constant";
import { toast } from "react-toastify";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import PetsRoundedIcon from "@mui/icons-material/PetsRounded";
import Switch from "@mui/material/Switch";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import BorderOuterRoundedIcon from "@mui/icons-material/BorderOuterRounded";
import { makeStyles } from "@material-ui/core/styles";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import upload from "../resources/icons/upload.png";
import plan from "../resources/icons/plan.png";

const label = { inputProps: { "aria-label": "Switch demo" } };
const useStyles = makeStyles({
  switchBase: {
    color: "white",
  },
  track: {
    height: "100%",
    minWidth: "100%",
    borderRadius: "7px",
    zIndex: -1,
    position: "relative",
    left: "-50%",
  },
});
const Moving = (props) => {
  const classes = useStyles();
  const history = useNavigate();
  const location = useLocation();
  const [details, setDetails] = useState({
    movingWithPets: false,
    currentFiles: [],
    newFiles: [],
    movingDate: new Date(),
    currAddress: "",
    newAddress: "",
    currGeo: { lat: null, lng: null },
    newGeo: { lat: null, lng: null },
    info: "",
    id: null,
  });
  const [title, setTitle] = useState({
    name: "8 weeks",
    task: [
      {
        name: "Create Inventory",
        completed: true,
      },
      {
        name: "Purchase Boxes",
        completed: false,
      },
    ],
  });
  const currUploadRef = useRef(null);
  const newUploadRef = useRef(null);

  useEffect(() => {
    if (location.state?.iter) {
      setDetails({
        ...details,
        movingDate: new Date(location.state.iter.date),
        id: location.state.iter.id,
      });
    }
  }, []);

  const createMove = () => {
    let url = "";
    let method = "";
    if (details.id == null) {
      url = "POST";
      method = API_URL + MOVE_REQUEST + "create-move";
    } else {
      url = "PUT";
      method = API_URL + UPDATE_MOVE + details.id;
    }

    invokeApi(method, url, {
      currentAddress: {
        lat: details.currGeo.lat,
        long: details.currGeo.lng,
        floorPlan: details.currentFiles[0].url,
      },
      newAddress: {
        lat: details.newGeo.lat,
        long: details.newGeo.lng,
        floorPlan: details.newFiles[0].url,
      },
      movingDate: moment(details.movingDate).format("yyyy-MM-DD"),
      pets: {
        isMoving: details.movingWithPets,
        details: {},
      },
    })
      .then((response) => {
        console.log(response);
        history("/tasks", { state: { id: response.id } });
      })
      .catch((err) => {
        toast.error("Creating Move request Failed");
      });
  };

  const fileInputChange = (e, bool) => {
    let array = [];
    let formData = new FormData();
    formData.append("upload", e.target.files[0]);
    invokeUploadFile(`${API_URL}${UPLOAD}?needTags=false`, formData)
      .then((response) => {
        array.push({
          name: response.data.name,
          url: response.data.location,
        });

        if (bool) {
          setDetails({ ...details, currentFiles: array });
        } else {
          setDetails({ ...details, newFiles: array });
        }
      })
      .catch((err) => {
        console.log("Upload failed err", err);
      });
  };

  const onChange = (name, val) => {
    setDetails({ ...details, [name]: val });
  };

  const newForm = () => {
    return (
      <div>
        <div style={{ margin: "10px", color: "white" }}>Move Details Form</div>
        <Grid container spacing={2} style={{ padding: "20px" }}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={10}>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      required
                      value={details.movingDate}
                      onChange={(e) => {
                        onChange("movingDate", e);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} style={{ width: "75%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item xs={12} md={10}>
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: 300,
                    backgroundColor: "#fbc0ff",
                    padding: "5px",
                    borderRadius: "5px",
                    height: "40px",
                  }}
                >
                  <span style={{ top: "10px", position: "relative" }}>
                    Moving with pets{" "}
                    <PetsRoundedIcon
                      style={{ width: "15px", height: "15px" }}
                    />
                  </span>
                  <span style={{ float: "right" }}>
                    <Switch
                      {...label}
                      checked={details.movingWithPets}
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          movingWithPets: !details.movingWithPets,
                        });
                      }}
                      classes={{
                        track: classes.track,
                        switchBase: classes.switchBase,
                      }}
                    />
                  </span>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={10}>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "15px",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: 200,
                      marginBottom: "5px",
                    }}
                  >
                    <GooglePlacesAutocomplete
                      apiKey={API_KEY}
                      selectProps={{
                        placeholder: "Current Address *",
                        onChange: (val) => {
                          onChange("currAddress", val.description);
                          geocodeByPlaceId(val.value.place_id).then((res) => {
                            onChange("currGeo", {
                              lat: res[0].geometry.location.lat(),
                              lng: res[0].geometry.location.lng(),
                            });
                          });
                        },
                      }}
                    />
                  </div>
                  {/* <TextareaAutosize
                    aria-label="minimum height"
                    minRows={1}
                    required
                    placeholder="Current Address *"
                    onChange={(e) => {
                      onChange("currAddress", e.target.value);
                    }}
                    value={details.currAddress}
                    style={{
                      width: "98%",
                      border: "none",
                      height: "25px",
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor: "#00000033",
                    }}
                  /> */}
                  <div
                    onClick={() => {
                      currUploadRef.current?.click();
                    }}
                    style={{ display: "flex", cursor: "pointer" }}
                  >
                    <img
                      src={upload}
                      alt="upload"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                    />
                    <input
                      ref={currUploadRef}
                      id="upload-video"
                      type="file"
                      onChange={(e) => {
                        fileInputChange(e, true);
                      }}
                      style={{ display: "none" }}
                    />
                    <span
                      style={{
                        fontSize: "12px",
                        padding: "3px",
                        fontWeight: 300,
                      }}
                    >
                      Upload Floor Plan
                    </span>
                    <img
                      src={plan}
                      alt="plan"
                      style={{
                        width: "18px",
                        height: "18px",
                        marginLeft: "auto",
                      }}
                    />
                  </div>
                  <div>
                    {details.currentFiles.map((iter) => {
                      return (
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: 200,
                            marginTop: "5px",
                          }}
                        >
                          {iter.name}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={10}>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "15px",
                    borderRadius: "10px",
                  }}
                >
                  {/* <TextareaAutosize
                    aria-label="minimum height"
                    minRows={1}
                    required
                    placeholder="New Address *"
                    onChange={(e) => {
                      onChange("newAddress", e.target.value);
                    }}
                    value={details.newAddress}
                    style={{
                      width: "98%",
                      border: "none",
                      height: "25px",
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor: "#00000033",
                    }}
                  /> */}
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: 200,
                      marginBottom: "5px",
                    }}
                  >
                    <GooglePlacesAutocomplete
                      apiKey={API_KEY}
                      selectProps={{
                        placeholder: "New Address *",
                        onChange: (val) => {
                          onChange("newAddress", val.description);
                          geocodeByPlaceId(val.value.place_id).then((res) => {
                            onChange("newGeo", {
                              lat: res[0].geometry.location.lat(),
                              lng: res[0].geometry.location.lng(),
                            });
                          });
                        },
                      }}
                    />
                  </div>
                  <div
                    onClick={() => {
                      newUploadRef.current?.click();
                    }}
                    style={{ display: "flex", cursor: "pointer" }}
                  >
                    <img
                      src={upload}
                      alt="upload"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                    />
                    <input
                      ref={newUploadRef}
                      id="upload-video"
                      type="file"
                      onChange={(e) => {
                        fileInputChange(e, false);
                      }}
                      style={{ display: "none" }}
                    />
                    <span
                      style={{
                        fontSize: "12px",
                        padding: "3px",
                        fontWeight: 300,
                      }}
                    >
                      Upload Floor Plan
                    </span>
                    <img
                      src={plan}
                      alt="plan"
                      style={{
                        width: "18px",
                        height: "18px",
                        marginLeft: "auto",
                      }}
                    />
                  </div>
                  <div>
                    {details.newFiles.map((iter) => {
                      return (
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: 200,
                            marginTop: "5px",
                          }}
                        >
                          {iter.name}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={10}>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "15px",
                    borderRadius: "10px",
                  }}
                >
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={1}
                    required
                    placeholder="Addition information *"
                    onChange={(e) => {
                      onChange("info", e.target.value);
                    }}
                    value={details.info}
                    style={{
                      width: "100%",
                      border: "none",
                      height: "30px",
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor: "#00000033",
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop: "25px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  width: "100px",
                  borderRadius: "20px",
                  color: "black",
                  fontWeight: "bold",
                  textTransform: "none",
                  backgroundColor: "white",
                  margin: "5px",
                  // backgroundImage:
                  //   "linear-gradient(90deg, rgb(243, 194, 203), rgb(176, 191, 245), rgb(171, 238, 171))",
                }}
                fullWidth
                onClick={() => {
                  createMove();
                }}
              >
                {details.id ? "Update" : "Save"}
              </Button>

              <Button
                variant="contained"
                style={{
                  width: "100px",
                  borderRadius: "20px",
                  color: "black",
                  fontWeight: "bold",
                  textTransform: "none",
                  backgroundColor: "white",
                  margin: "5px",
                  // backgroundImage:
                  //   "linear-gradient(90deg, rgb(243, 194, 203), rgb(176, 191, 245), rgb(171, 238, 171))",
                }}
                fullWidth
                onClick={() => {
                  history(-1);
                }}
              >
                Cancel
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  return <div style={{ padding: "5px" }}>{newForm()}</div>;
};

export default Moving;

import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  API_URL,
  GET_FIRST_NIGHT_BAGS,
  UPDATE_FIRST_NIGHT_BAGS,
} from "../components/api/constant";
import { invokeApi } from "./api/index";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  margin: "10px",
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}
const FirstNightBag = (props) => {
  const location = useLocation();
  const [taskList, setTaskList] = useState([]);

  useEffect(() => {
    let serviceId = location.state.id;
    invokeApi(
      "GET",
      `${API_URL}${GET_FIRST_NIGHT_BAGS.replace("{serviceId}", serviceId)}`
    )
      .then((response) => {
        setTaskList(response);
      })
      .catch((err) => {
        console.log("Fetching First Night Bags details failed");
      });
  }, []);

  const onCompleteChange = (task) => {
    let serviceId = location.state.id;
    invokeApi(
      "PUT",
      `${API_URL}${UPDATE_FIRST_NIGHT_BAGS.replace("{itemId}", task.id).replace(
        "{serviceId}",
        serviceId
      )}`,
      {
        completed: !task.completed,
      }
    )
      .then((response) => {
        if (response.serviceId) {
          let taskListTemp = taskList.map((iter) => {
            if (iter.id == task.id) {
              return response;
            } else {
              return iter;
            }
          });
          setTaskList(taskListTemp);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <div style={{ padding: "5px" }}>
      <div>
        <ArrowBackIcon
          style={{
            color: "white",
            fontSize: "30px",
            margin: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            props.reset();
          }}
        />{" "}
        <span
          style={{
            fontSize: "25px",
            color: "white",
            position: "relative",
            top: "-15px",
          }}
        >
          {" "}
          First Night Bag
        </span>
      </div>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "20px",
          margin: "10px ",
          padding: "20px",
          display: "flex",
        }}
      >
        <div>Activities</div>
        <div style={{ marginLeft: "auto", fontWeight: 200 }}>
          {taskList.filter((iter) => iter.completed).length}/{taskList.length}{" "}
          Completed
        </div>
      </div>
      <div style={{ padding: "15px", height: "55vh", overflow: "auto" }}>
        {taskList.length > 0
          ? taskList.map((iter, pos) => {
              return (
                <div
                  style={{
                    margin: "10px 20px 10px 20px",
                    height: "60px",
                    borderBottom:
                      pos == taskList.length - 1 ? "none" : "1px dashed white",
                  }}
                >
                  <BpCheckbox
                    checked={iter.completed}
                    onChange={() => {
                      onCompleteChange(iter);
                    }}
                  />{" "}
                  <span
                    style={{
                      textDecoration: iter.completed ? "line-through" : "none",
                      fontWeight: 200,
                      color: "white",
                    }}
                  >
                    {iter.name}
                  </span>
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default FirstNightBag;

import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Address = (props) => {
  return (
    <div style={{ padding: "5px" }}>
      <div>
        <ArrowBackIcon
          style={{
            color: "white",
            fontSize: "30px",
            margin: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            props.reset();
          }}
        />{" "}
        <span
          style={{
            fontSize: "25px",
            color: "white",
            position: "relative",
            top: "-15px",
          }}
        >
          {" "}
          Address Update
        </span>
      </div>
    </div>
  );
};

export default Address;

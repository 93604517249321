import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  API_URL,
  GET_ALL_PETS,
  UPLOAD,
  CREATE_INSURANCE,
  CREATE_PET,
  UPDATE_PET,
  GET_USER_INSURANCES,
  DELETE_PET,
} from "../components/api/constant";
import { invokeApi, invokeUploadFile } from "./api/index";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { TextField, Button } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import upload from "../resources/icons/upload.png";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  margin: "10px",
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Pets = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  const [petsList, setPetsList] = useState([]);
  const [modal, setModal] = React.useState({
    open: false,
    details: null,
  });
  const [loading, setLoading] = useState(false);
  const [insuranceModal, setInsuranceModal] = useState(false);
  const [insuranceDetails, setInsuranceDetails] = useState(null);
  const [insuranceList, setInsuranceList] = useState([]);
  const [selectedIns, setSelectedIns] = useState(null);
  const loc = useLocation();
  const uploadRef = useRef(null);

  const modalNameFun = (val) => {
    switch (val) {
      case 0:
        return "Animal";
      case 1:
        return "Bird";
      default:
        return "Other";
    }
  };

  const fileInputChange = (e, bool) => {
    let array = [];
    let formData = new FormData();
    formData.append("upload", e.target.files[0]);
    setLoading(true);
    invokeUploadFile(`${API_URL}${UPLOAD}?needTags=false`, formData)
      .then((response) => {
        array.push({
          name: response.data.name,
          url: response.data.location,
        });
        setInsuranceDetails({
          ...insuranceDetails,
          insuranceFiles: array,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Upload failed err", err);
      });
  };

  const InsurancemodalFun = () => {
    return (
      <Modal
        disableEnforceFocus={true}
        open={insuranceModal}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setModal({
              open: false,
              details: null,
            });
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Backdrop sx={{ color: "#fff", zIndex: 9999999 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box
            style={{
              p: 4,
              position: "absolute",
              top: "50%",
              left: "50%",
              padding: "20px",
              width: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#935252",
              border: "none",
              borderRadius: "20px",
            }}
          >
            <div
              style={{
                textAlign: "center",
                padding: "0px 0px 20px 0px",
                fontSize: "25px",
              }}
            >
              Create Pet Insurance
            </div>
            <Grid container spacing={5} style={{ marginLeft: "-20px" }}>
              <Grid item xs={12} md={3}>
                <div>Reg No</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="regNo"
                  className={
                    insuranceDetails?.regNo < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setInsuranceDetails({
                      ...insuranceDetails,
                      regNo: e.target.value,
                    });
                  }}
                  color="primary"
                  placeholder="Reg no."
                  value={insuranceDetails?.regNo}
                  variant="standard"
                  style={{ width: "75%" }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <div>Cost</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="cost"
                  className={
                    insuranceDetails?.cost < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setInsuranceDetails({
                      ...insuranceDetails,
                      cost: e.target.value,
                    });
                  }}
                  color="primary"
                  placeholder="Cost"
                  value={insuranceDetails?.cost}
                  style={{ width: "75%" }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <div>Insurance Comprehensive</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="insuranceComprehensive"
                  className={
                    insuranceDetails?.insuranceComprehensive < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setInsuranceDetails({
                      ...insuranceDetails,
                      insuranceComprehensive: e.target.value,
                    });
                  }}
                  color="primary"
                  placeholder="Insurance Compprehensive"
                  value={insuranceDetails?.insuranceComprehensive}
                  style={{ width: "75%" }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <div>Location</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="location"
                  className={
                    insuranceDetails?.location < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setInsuranceDetails({
                      ...insuranceDetails,
                      location: e.target.value,
                    });
                  }}
                  color="primary"
                  placeholder="Location"
                  value={insuranceDetails?.location}
                  style={{ width: "75%" }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <div
                  onClick={() => {
                    uploadRef.current?.click();
                  }}
                  style={{ display: "flex", cursor: "pointer" }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "auto",
                      marginRight: "50px",
                    }}
                  >
                    <input
                      ref={uploadRef}
                      id="upload-video"
                      type="file"
                      onChange={(e) => {
                        fileInputChange(e, true);
                      }}
                      style={{ display: "none" }}
                    />
                    <span
                      style={{
                        fontSize: "12px",
                        padding: "3px",
                        fontWeight: 300,
                        color: "white",
                      }}
                    >
                      Upload Insurance File
                    </span>
                    <img
                      src={upload}
                      alt="upload"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                        marginLeft: "5px",
                        color: "white",
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  {insuranceDetails?.insuranceFiles?.map((iter) => {
                    return (
                      <div
                        style={{
                          fontSize: "12px",
                          color: "white",
                          marginLeft: "auto",
                          marginRight: "50px",
                          fontWeight: 300,
                          padding: "5px 30px 5px 5px",
                        }}
                      >
                        {iter.name}
                      </div>
                    );
                  })}
                </div>
              </Grid>
            </Grid>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  margin: "10px",
                }}
                onClick={() => {
                  let serviceId = loc.state.id;
                  let payload = {
                    policyDoc:
                      insuranceDetails.insuranceFiles[0].url == ""
                        ? "NA"
                        : insuranceDetails.insuranceFiles[0].url,
                    insuranceType: "Pet",
                    details: {
                      regNo: insuranceDetails.regNo,
                      cost: insuranceDetails.cost,
                      insuranceComprehensive:
                        insuranceDetails.insuranceComprehensive,
                      location: insuranceDetails.location,
                    },
                  };
                  setLoading(true);
                  invokeApi(
                    "POST",
                    `${API_URL}${CREATE_INSURANCE.replace(
                      "{serviceId}",
                      serviceId
                    )}`,
                    payload
                  )
                    .then((res) => {
                      if (res.id) {
                        let list = insuranceList;
                        list.push(res);
                        setInsuranceList(list);
                        setInsuranceDetails(null);
                        setInsuranceModal(false);
                        toast.success("Insurance Creation Successful");
                      }
                      setLoading(false);
                    })
                    .catch((err) => {
                      setLoading(false);
                      toast.error("Insurance creation failed");
                    });
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  margin: "10px",
                }}
                onClick={() => {
                  setInsuranceDetails(null);
                  setInsuranceModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </>
      </Modal>
    );
  };

  const modalFun = () => {
    return (
      <Modal
        disableEnforceFocus={true}
        open={modal.open == true ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setModal({
              open: false,
              details: null,
            });
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Backdrop sx={{ color: "#fff", zIndex: 9999999 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box
            style={{
              p: 4,
              position: "absolute",
              top: "50%",
              left: "50%",
              padding: "20px",
              width: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#935252",
              border: "none",
              borderRadius: "20px",
            }}
          >
            <div
              style={{
                textAlign: "center",
                padding: "0px 0px 20px 0px",
                fontSize: "25px",
              }}
            >
              {modal.details?.id == undefined
                ? "Add " + modalNameFun(expanded) + " Pet"
                : "Edit " + modal.details?.type + " Pet"}
            </div>
            <Grid container spacing={5} style={{ marginLeft: "-20px" }}>
              <Grid item xs={12} md={3}>
                <div>Name</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="name"
                  className={
                    modal.details?.name < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: { ...modal.details, name: e.target.value },
                    });
                  }}
                  color="primary"
                  placeholder="Name"
                  value={modal.details?.name}
                  variant="standard"
                  style={{ width: "75%" }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <div>Tag Number</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="tagNumber"
                  className={
                    modal.details?.tagNumber < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: { ...modal.details, tagNumber: e.target.value },
                    });
                  }}
                  color="primary"
                  placeholder="Tag Number"
                  value={modal.details?.tagNumber}
                  style={{ width: "75%" }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <div>Veterinary Name</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="veterinaryName"
                  className={
                    modal.details?.veterinaryDetails.vetName < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: {
                        ...modal.details,
                        veterinaryDetails: {
                          ...modal.details?.veterinaryDetails,
                          vetName: e.target.value,
                        },
                      },
                    });
                  }}
                  color="primary"
                  placeholder="Veterinary Name"
                  value={modal.details?.veterinaryDetails.vetName}
                  style={{ width: "75%" }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <div>Veterinary Contact</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="vetContact"
                  className={
                    modal.details?.veterinaryDetails.vetContact < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: {
                        ...modal.details,
                        veterinaryDetails: {
                          ...modal.details?.veterinaryDetails,
                          vetContact: e.target.value,
                        },
                      },
                    });
                  }}
                  color="primary"
                  placeholder="Veterinary Contact"
                  value={modal.details?.veterinaryDetails.vetContact}
                  style={{ width: "75%" }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "15px" }}>
                <div>
                  Select the Insurance{" "}
                  <span>
                    <AddIcon
                      style={{
                        backgroundColor: "white",
                        borderRadius: "50%",
                        position: "relative",
                        top: "5px",
                        left: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setInsuranceModal(true);
                      }}
                    />
                  </span>
                </div>
                <div
                  style={{
                    width: "85%",
                    maxHeight: "25vh",
                    overflow: "auto",
                    marginTop: "10px",
                  }}
                >
                  {insuranceList.map((iter, pos) => {
                    return (
                      <div
                        style={{
                          margin: "0px 20px 0px 20px",
                          height: "40px",
                          borderBottom:
                            pos == insuranceList.length - 1
                              ? "none"
                              : "1px solid gray",
                        }}
                      >
                        <BpCheckbox
                          checked={selectedIns == iter.id}
                          onChange={() => {
                            if (iter.id == selectedIns) {
                              setSelectedIns(null);
                            } else {
                              setSelectedIns(iter.id);
                            }
                          }}
                        />{" "}
                        <span
                          style={{
                            fontWeight: 200,
                            color: "white",
                          }}
                        >
                          {iter.details.regNo}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </Grid>
            </Grid>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  margin: "10px",
                }}
                onClick={() => {
                  let list = petsList;
                  let serviceId = loc.state.id;
                  let payload = {
                    name: modal.details.name,
                    type:
                      modal.details.id == undefined
                        ? modalNameFun(expanded)
                        : modal.details.type,
                    tagNumber: modal.details.tagNumber,
                    veterinaryDetails: {
                      vetName: modal.details.veterinaryDetails.vetName,
                      vetContact: modal.details.veterinaryDetails.vetContact,
                    },
                    policyDoc:
                      selectedIns == null
                        ? undefined
                        : insuranceList.find((iter) => iter.id == selectedIns)
                            ?.policyDoc,
                  };

                  if (modal.details.id == undefined) {
                    setLoading(true);
                    invokeApi(
                      "POST",
                      `${API_URL}${CREATE_PET.replace(
                        "{serviceId}",
                        serviceId
                      )}`,
                      payload
                    )
                      .then((res) => {
                        if (res.id) {
                          list.push(res);
                          setPetsList(list);
                          setModal({
                            open: false,
                            details: null,
                          });
                          toast.success("Pet Creation Successful");
                        }
                        setLoading(false);
                      })
                      .catch((err) => {
                        setLoading(false);
                        toast.error("Pet creation failed");
                      });
                  } else {
                    setLoading(false);
                    invokeApi(
                      "PUT",
                      `${API_URL}${UPDATE_PET.replace(
                        "{serviceId}",
                        serviceId
                      )}` + modal.details.id,
                      payload
                    )
                      .then((res) => {
                        if (res.id) {
                          let updatedList = list.map((iter) => {
                            if (iter.id == res.id) {
                              return res;
                            } else {
                              return iter;
                            }
                          });
                          setPetsList(updatedList);
                          setModal({
                            open: false,
                            details: null,
                          });
                          toast.success("Pet updation Successful");
                        }
                        setLoading(false);
                      })
                      .catch((err) => {
                        toast.error("Pet updation failed");
                        setLoading(false);
                      });
                  }
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  margin: "10px",
                  display: modal.details?.id == undefined ? "none" : "",
                }}
                onClick={() => {
                  setLoading(true);
                  invokeApi(
                    "DELETE",
                    `${API_URL}${DELETE_PET}` + modal.details.id
                  )
                    .then((res) => {
                      if (res.id) {
                        let updatedList = petsList.filter((iter) => {
                          return iter.id != res.id;
                        });
                        setPetsList(updatedList);
                        setModal({
                          open: false,
                          details: null,
                        });
                        toast.success("Pet Deletion Successful");
                      }
                      setLoading(false);
                    })
                    .catch((err) => {
                      toast.error("Pet Deletion failed");
                      setLoading(false);
                    });
                }}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  margin: "10px",
                }}
                onClick={() => {
                  setModal({
                    open: false,
                    details: null,
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </>
      </Modal>
    );
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    invokeApi("GET", `${API_URL}${GET_ALL_PETS}`)
      .then((response) => {
        setPetsList(response);
      })
      .catch((error) => {
        console.log("error", error);
      });

    invokeApi("GET", `${API_URL}${GET_USER_INSURANCES}`)
      .then((response) => {
        console.log(response);
        let pets = response.filter((iter) => iter.insuranceType == "Pet");
        console.log(pets);
        setInsuranceList(pets);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const accordianDetailsFun = (val, name) => {
    let result = [];
    val.forEach((iter, pos) => {
      if (iter.type == name) {
        iter["index"] = pos;
        result.push(iter);
      }
    });
    return (
      <Grid container spacing={2}>
        {result.length > 0 ? (
          result.map((iter) => {
            return (
              <Grid item xs={12} md={4}>
                <div
                  style={{
                    backgroundColor: "#e3e0df",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setModal({
                        open: true,
                        details: { ...iter },
                      });
                      setSelectedIns(
                        insuranceList.find((i) => i.policyDoc == iter.policyDoc)
                          ?.id
                      );
                    }}
                  >
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          fontWeight: 200,
                        }}
                      >
                        Name
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 200,
                        }}
                      >
                        {iter.name}
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          fontWeight: 200,
                        }}
                      >
                        Tag Number
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 200,
                        }}
                      >
                        {iter.tagNumber}
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          fontWeight: 200,
                        }}
                      >
                        Vaterinary Name
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 200,
                        }}
                      >
                        {iter.veterinaryDetails.vetName}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          fontWeight: 200,
                        }}
                      >
                        Vaterinary Contact
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 200,
                        }}
                      >
                        {iter.veterinaryDetails.vetContact}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            );
          })
        ) : (
          <div
            style={{
              textAlign: "center",
              width: "100%",
              padding: "15px",
              marginTop: "10px",
            }}
          >
            No Entries Available
          </div>
        )}
      </Grid>
    );
  };

  return (
    <div style={{ padding: "5px" }}>
      <Fab
        size="small"
        aria-label="add"
        style={{
          borderRadius: "25px",
          backgroundColor: "#ee17ee",
          color: "white",
          position: "absolute",
          top: "80%",
          left: "90%",
          cursor: expanded === false ? "not-allowed" : "pointer",
          zIndex: 99999999,
        }}
        onClick={() => {
          if (expanded !== false) {
            setModal({
              open: true,
              details: {
                veterinaryDetails: [
                  {
                    vetName: "",
                    vetContact: "",
                  },
                ],
              },
            });
          }
        }}
      >
        <AddIcon />
      </Fab>
      <div>
        <ArrowBackIcon
          style={{
            color: "white",
            fontSize: "30px",
            margin: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            props.reset();
          }}
        />{" "}
        <span
          style={{
            fontSize: "25px",
            color: "white",
            position: "relative",
            top: "-15px",
          }}
        >
          {" "}
          Pets
        </span>
      </div>
      <div style={{ margin: "0px 30px 0px 50px" }}>
        <Accordion
          expanded={expanded === 0}
          onChange={handleChange(0)}
          style={{ borderRadius: "10px 10px 0px 0px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Animal</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {accordianDetailsFun(petsList, "Animal")}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 1} onChange={handleChange(1)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Bird</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {accordianDetailsFun(petsList, "Bird")}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 2}
          style={{ borderRadius: "0px 0px 10px 10px" }}
          onChange={handleChange(2)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Other</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {accordianDetailsFun(petsList, "Other")}
          </AccordionDetails>
        </Accordion>
      </div>
      {modalFun()}
      {InsurancemodalFun()}
    </div>
  );
};

export default Pets;

import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { TextField, Button } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import {
  GET_BOXES,
  API_URL,
  CREATE_BOX,
  UPDATE_BOX,
  DELETE_BOX,
} from "../components/api/constant";
import { toast } from "react-toastify";
import { invokeApi } from "./api/index";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const Boxes = (props) => {
  const location = useLocation();
  const [expanded, setExpanded] = React.useState(false);
  const [modal, setModal] = React.useState({
    open: false,
    details: null,
  });
  const [boxList, setBoxList] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let serviceId = location.state.id;
    invokeApi("GET", `${API_URL}${GET_BOXES.replace("{serviceId}", serviceId)}`)
      .then((response) => {
        setBoxList(response);
      })
      .catch((err) => {
        console.log("Fetching boxes details failed");
      });
  }, []);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const modalNameFun = (val) => {
    switch (val) {
      case 0:
        return "Box";
      case 1:
        return "Wrap";
      default:
        return "Tape";
    }
  };
  const modalFun = () => {
    return (
      <Modal
        disableEnforceFocus={true}
        open={modal.open == true ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setModal({
              open: false,
              details: null,
            });
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box className="createBox" style={{ p: 4 }}>
            <div
              style={{
                textAlign: "center",
                padding: "0px 0px 20px 0px",
                fontSize: "25px",
              }}
            >
              {modal.details === null
                ? "Create " + modalNameFun(expanded)
                : "Edit " + modal.details?.type}
            </div>
            <Grid container spacing={2} style={{}}>
              <Grid item xs={12} md={2}>
                <div>Name</div>
              </Grid>
              <Grid item xs={12} md={10}>
                <TextField
                  name="name"
                  className={
                    modal.details?.title < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: { ...modal.details, title: e.target.value },
                    });
                  }}
                  color="primary"
                  placeholder="Name"
                  value={modal.details?.title}
                  variant="standard"
                  style={{ width: "75%" }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <div>Description</div>
              </Grid>
              <Grid item xs={12} md={10}>
                <TextField
                  name="description"
                  className={
                    modal.details?.description < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: {
                        ...modal.details,
                        description: e.target.value,
                      },
                    });
                  }}
                  color="primary"
                  placeholder="Name"
                  value={modal.details?.description}
                  style={{ width: "75%" }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>
            </Grid>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  margin: "10px",
                }}
                onClick={() => {
                  let list = boxList;
                  let serviceId = location.state.id;
                  let payload = {};
                  if (modal.details.id == undefined) {
                    payload["type"] =
                      modalNameFun(expanded).toLocaleLowerCase();
                    payload["title"] = modal.details.title;
                    payload["description"] = modal.details.description;
                    payload["count"] = 1;
                    setLoading(true);
                    invokeApi(
                      "POST",
                      `${API_URL}${CREATE_BOX.replace(
                        "{serviceId}",
                        serviceId
                      )}`,
                      payload
                    )
                      .then((res) => {
                        if (res.id) {
                          list.push(res);
                          setBoxList(list);
                          setModal({
                            open: false,
                            details: null,
                          });
                          setLoading(false);
                        }
                      })
                      .catch((err) => {
                        toast.error("Box creation failed");
                        setLoading(false);
                      });
                  } else {
                    payload["type"] = modal.details.type;
                    payload["title"] = modal.details.title;
                    payload["description"] = modal.details.description;
                    payload["count"] = modal.details.count;
                    payload["archive"] = modal.details.archive;

                    invokeApi(
                      "PUT",
                      `${API_URL}${UPDATE_BOX.replace(
                        "{serviceId}",
                        serviceId
                      )}` + modal.details.id,
                      payload
                    )
                      .then((res) => {
                        if (res.id) {
                          let updatedList = list.map((iter) => {
                            if (iter.id == res.id) {
                              return res;
                            } else {
                              return iter;
                            }
                          });
                          setBoxList(updatedList);
                          setModal({
                            open: false,
                            details: null,
                          });
                          setLoading(false);
                        }
                      })
                      .catch((err) => {
                        toast.error("Box updation failed");
                        setLoading(false);
                      });
                  }
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  margin: "10px",
                }}
                onClick={() => {
                  setModal({
                    open: false,
                    details: null,
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </>
      </Modal>
    );
  };

  const boxValueUpdateFun = (index, val) => {
    let list = boxList;
    list[index].count = parseInt(val);
    setBoxList(list);
    setRefresh(Math.random());
  };

  const saveAll = () => {
    let calls = [];
    let serviceId = location.state.id;
    boxList.forEach((iter) => {
      let payload = {};
      payload["type"] = iter.type;
      payload["title"] = iter.title;
      payload["description"] = iter.description;
      payload["count"] = iter.count;
      payload["archive"] = iter.archive;
      calls.push(
        invokeApi(
          "PUT",
          `${API_URL}${UPDATE_BOX.replace("{serviceId}", serviceId)}` + iter.id,
          payload
        )
      );
    });
    Promise.all(calls)
      .then((values) => {
        toast.success("Saved Successfully");
      })
      .catch((err) => {
        toast.error("Save failed");
      });
  };

  const accordianDetailsFun = (val, name) => {
    let result = [];
    val.forEach((iter, pos) => {
      if (iter.type == name) {
        iter["index"] = pos;
        result.push(iter);
      }
    });
    return (
      <Grid container spacing={2}>
        {result.length > 0 ? (
          result.map((iter) => {
            return (
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setModal({
                        open: true,
                        details: { ...iter },
                      });
                    }}
                  >
                    <div>{iter.title}</div>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "gray",
                        padding: "10px 0px 0px 0px",
                        fontWeight: 200,
                      }}
                    >
                      {iter.description}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ display: "flex" }}>
                      <TextField
                        name={iter.type + iter.index}
                        key={iter.type + iter.index}
                        onChange={(e) => {
                          boxValueUpdateFun(iter.index, e.target.value);
                        }}
                        value={iter.count}
                        color="primary"
                        type="number"
                        style={{
                          width: "30%",
                          border: "1px solid black",
                          padding: "2px 2px 2px 7px",
                          borderRadius: "5px",
                        }}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                      <div
                        onClick={() => {
                          let list = boxList;
                          invokeApi(
                            "DELETE",
                            `${API_URL}${DELETE_BOX.replace(
                              "{boxId}",
                              iter.id
                            )}`
                          )
                            .then((res) => {
                              if (res.id) {
                                let updatedList = list.filter((iter) => {
                                  return iter.id != res.id;
                                });
                                setBoxList(updatedList);
                                setModal({
                                  open: false,
                                  details: null,
                                });
                              }
                            })
                            .catch((err) => {
                              toast.error("Box Deletion failed");
                            });
                        }}
                        style={{
                          backgroundColor: "gray",
                          borderRadius: "25px",
                          width: "20px",
                          textAlign: "center",
                          cursor: "pointer",
                          margin: "10px 5px 5px 5px",
                          height: "20px",
                        }}
                      >
                        x
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <div
            style={{
              textAlign: "center",
              width: "100%",
              padding: "15px",
              marginTop: "10px",
            }}
          >
            No Entries Available
          </div>
        )}
      </Grid>
    );
  };
  return (
    <div style={{ padding: "5px" }}>
      <Fab
        size="small"
        aria-label="add"
        style={{
          borderRadius: "25px",
          backgroundColor: "#ee17ee",
          color: "white",
          position: "absolute",
          top: "80%",
          left: "90%",
          cursor: expanded === false ? "not-allowed" : "pointer",
        }}
        onClick={() => {
          if (expanded !== false) {
            setModal({
              open: true,
              details: null,
            });
          }
        }}
      >
        <AddIcon />
      </Fab>
      <div>
        <ArrowBackIcon
          style={{
            color: "white",
            fontSize: "30px",
            margin: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            props.reset();
          }}
        />{" "}
        <span
          style={{
            fontSize: "25px",
            color: "white",
            position: "relative",
            top: "-15px",
          }}
        >
          {" "}
          Boxes
        </span>
      </div>
      <div style={{ margin: "0px 30px 0px 50px" }}>
        <Accordion
          expanded={expanded === 0}
          onChange={handleChange(0)}
          style={{ borderRadius: "10px 10px 0px 0px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Boxes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {accordianDetailsFun(boxList, "box")}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 1} onChange={handleChange(1)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Wrapping
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {accordianDetailsFun(boxList, "wrap")}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 2}
          style={{ borderRadius: "0px 0px 10px 10px" }}
          onChange={handleChange(2)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Tape</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {accordianDetailsFun(boxList, "tape")}
          </AccordionDetails>
        </Accordion>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "20px",
        }}
      >
        <Button
          variant="contained"
          style={{
            textTransform: "none",
            backgroundColor: "#753131",
            color: "white",
            fontWeight: "bold",
            borderRadius: "10px",
            margin: "10px",
          }}
          onClick={() => {
            saveAll();
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          style={{
            textTransform: "none",
            backgroundColor: "#753131",
            color: "white",
            fontWeight: "bold",
            borderRadius: "10px",
            margin: "10px",
          }}
          onClick={() => {
            props.reset();
          }}
        >
          Cancel
        </Button>
      </div>
      {modalFun()}
    </div>
  );
};

export default Boxes;

import * as React from "react";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import move from "../resources/icons/move.png";
import senior_transitioning from "../resources/icons/senior_transitioning.png";
import renovation from "../resources/icons/renovation.png";
import other from "../resources/icons/other.png";

const Home = (props) => {
  const history = useNavigate();
  return (
    <div style={{ padding: "30px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "10vh",
          marginTop: "20vh",
        }}
      >
        <div
          style={{
            fontSize: "50px",
            color: "white",
          }}
        >
          Life organised for life
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
          }}
          style={{ width: "30%" }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search for Services"
            inputProps={{ "aria-label": "search" }}
          />
          <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      <div
        style={{
          marginTop: "10%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            borderRadius: "10px",
            boxShadow: "3px 3px #2a1d15",
          }}
        >
          <div
            style={{ margin: "20px", cursor: "pointer" }}
            onClick={() => {
              history("/moving");
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={move}
                alt="Move"
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </div>
            <div style={{ textAlign: "center" }}>Moving</div>
          </div>
          <div
            style={{ margin: "20px", cursor: "not-allowed" }}
            // onClick={() => {
            //   history("/senior-transitioning");
            // }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={senior_transitioning}
                alt="Senior Transitioning.png"
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </div>
            <div style={{ textAlign: "center" }}>Senior transitioning</div>
          </div>
          <div
            style={{ margin: "20px", cursor: "not-allowed" }}
            // onClick={() => {
            //   history("/renovation");
            // }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={renovation}
                alt="Renovation"
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </div>
            <div style={{ textAlign: "center" }}>Renovation</div>
          </div>
          <div
            style={{ margin: "20px", cursor: "not-allowed" }}
            // onClick={() => {
            //   history("/other-service");
            // }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={other}
                alt="Other Service"
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </div>
            <div style={{ textAlign: "center" }}>Other Services</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

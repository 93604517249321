import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { API_URL, LIST_UTILS } from "../components/api/constant";
import { toast } from "react-toastify";
import { invokeApi } from "./api/index";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { TextField, Button } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import StorageIcon from "@mui/icons-material/Storage";
import truck from "../resources/icons/truck.png";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import PestControlIcon from "@mui/icons-material/PestControl";

const Service = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [utilityList, setUtilityList] = useState({});
  const [modal, setModal] = React.useState({
    open: false,
    details: null,
  });
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // const modalNameFun = (val) => {
  //   switch (val) {
  //     case 0:
  //       return "Box";
  //     case 1:
  //       return "Wrap";
  //     default:
  //       return "Tape";
  //   }
  // };
  // const modalFun = () => {
  //   return (
  //     <Modal
  //       disableEnforceFocus={true}
  //       open={modal.open == true ? true : false}
  //       onClose={(e, reason) => {
  //         if (reason != "backdropClick") {
  //           setModal({
  //             open: false,
  //             details: null,
  //           });
  //         }
  //       }}
  //       aria-labelledby="modal-modal-title"
  //       aria-describedby="modal-modal-description"
  //     >
  //       <>
  //         <Backdrop
  //           sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
  //           open={loading}
  //         >
  //           <CircularProgress color="inherit" />
  //         </Backdrop>
  //         <Box className="createBox" style={{ p: 4 }}>
  //           <div
  //             style={{
  //               textAlign: "center",
  //               padding: "0px 0px 20px 0px",
  //               fontSize: "25px",
  //             }}
  //           >
  //             {modal.details ? "Create A Service" : "Update Service"}
  //           </div>
  //           <Grid container spacing={2} style={{}}>
  //             <Grid item xs={12} md={2}>
  //               <div>Name</div>
  //             </Grid>
  //             <Grid item xs={12} md={10}>
  //               <TextField
  //                 name="name"
  //                 className={
  //                   modal.details?.title < 1
  //                     ? "createGroupFieldsError"
  //                     : "createGroupFields"
  //                 }
  //                 onChange={(e) => {
  //                   setModal({
  //                     ...modal,
  //                     details: { ...modal.details, title: e.target.value },
  //                   });
  //                 }}
  //                 color="primary"
  //                 placeholder="Name"
  //                 value={modal.details?.title}
  //                 variant="standard"
  //                 style={{ width: "75%" }}
  //                 InputProps={{
  //                   disableUnderline: true,
  //                 }}
  //               />
  //             </Grid>

  //             <Grid item xs={12} md={2}>
  //               <div>Description</div>
  //             </Grid>
  //             <Grid item xs={12} md={10}>
  //               <TextField
  //                 name="description"
  //                 className={
  //                   modal.details?.description < 1
  //                     ? "createGroupFieldsError"
  //                     : "createGroupFields"
  //                 }
  //                 onChange={(e) => {
  //                   setModal({
  //                     ...modal,
  //                     details: {
  //                       ...modal.details,
  //                       description: e.target.value,
  //                     },
  //                   });
  //                 }}
  //                 color="primary"
  //                 placeholder="Name"
  //                 value={modal.details?.description}
  //                 style={{ width: "75%" }}
  //                 variant="standard"
  //                 InputProps={{
  //                   disableUnderline: true,
  //                 }}
  //               />
  //             </Grid>
  //           </Grid>

  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "center",
  //               paddingTop: "20px",
  //             }}
  //           >
  //             <Button
  //               variant="contained"
  //               style={{
  //                 textTransform: "none",
  //                 backgroundColor: "white",
  //                 color: "black",
  //                 fontWeight: "bold",
  //                 borderRadius: "10px",
  //                 margin: "10px",
  //               }}
  //               // onClick={() => {
  //               //   let list = boxList;
  //               //   let serviceId = location.state.id;
  //               //   let payload = {};
  //               //   if (modal.details.id == undefined) {
  //               //     payload["type"] =
  //               //       modalNameFun(expanded).toLocaleLowerCase();
  //               //     payload["title"] = modal.details.title;
  //               //     payload["description"] = modal.details.description;
  //               //     payload["count"] = 1;
  //               //     setLoading(true);
  //               //     invokeApi(
  //               //       "POST",
  //               //       `${API_URL}${CREATE_BOX.replace(
  //               //         "{serviceId}",
  //               //         serviceId
  //               //       )}`,
  //               //       payload
  //               //     )
  //               //       .then((res) => {
  //               //         if (res.id) {
  //               //           list.push(res);
  //               //           setBoxList(list);
  //               //           setModal({
  //               //             open: false,
  //               //             details: null,
  //               //           });
  //               //           setLoading(false);
  //               //         }
  //               //       })
  //               //       .catch((err) => {
  //               //         toast.error("Box creation failed");
  //               //         setLoading(false);
  //               //       });
  //               //   } else {
  //               //     payload["type"] = modal.details.type;
  //               //     payload["title"] = modal.details.title;
  //               //     payload["description"] = modal.details.description;
  //               //     payload["count"] = modal.details.count;
  //               //     payload["archive"] = modal.details.archive;

  //               //     invokeApi(
  //               //       "PUT",
  //               //       `${API_URL}${UPDATE_BOX.replace(
  //               //         "{serviceId}",
  //               //         serviceId
  //               //       )}` + modal.details.id,
  //               //       payload
  //               //     )
  //               //       .then((res) => {
  //               //         if (res.id) {
  //               //           let updatedList = list.map((iter) => {
  //               //             if (iter.id == res.id) {
  //               //               return res;
  //               //             } else {
  //               //               return iter;
  //               //             }
  //               //           });
  //               //           setBoxList(updatedList);
  //               //           setModal({
  //               //             open: false,
  //               //             details: null,
  //               //           });
  //               //           setLoading(false);
  //               //         }
  //               //       })
  //               //       .catch((err) => {
  //               //         toast.error("Box updation failed");
  //               //         setLoading(false);
  //               //       });
  //               //   }
  //               // }}
  //             >
  //               Save
  //             </Button>
  //             <Button
  //               variant="contained"
  //               style={{
  //                 textTransform: "none",
  //                 backgroundColor: "white",
  //                 color: "black",
  //                 fontWeight: "bold",
  //                 borderRadius: "10px",
  //                 margin: "10px",
  //               }}
  //               onClick={() => {
  //                 setModal({
  //                   open: false,
  //                   details: null,
  //                 });
  //               }}
  //             >
  //               Cancel
  //             </Button>
  //           </div>
  //         </Box>
  //       </>
  //     </Modal>
  //   );
  // };

  // useEffect(() => {
  //   let serviceId = location.state.id;
  //   invokeApi(
  //     "GET",
  //     `${API_URL}${LIST_UTILS.replace("{serviceId}", serviceId)}`
  //   )
  //     .then((response) => {
  //       console.log("utils", response);
  //       let obj = {};
  //       response.forEach((iter) => {
  //         let array = obj[iter.type] ? obj[iter.type] : [];
  //         array.push(iter);
  //         obj[iter.type] = array;
  //       });
  //       setUtilityList(obj);
  //     })
  //     .catch((err) => {
  //       console.log("Fetching Service details failed");
  //     });
  // }, []);

  // const accordianDetailsFun = (val) => {
  //   return (
  //     <Grid container spacing={2}>
  //       {val.length > 0 ? (
  //         val.map((iter) => {
  //           return (
  //             <Grid item xs={12} md={6}>
  //               <div
  //                 style={{
  //                   backgroundColor: "#e3e0df",
  //                   padding: "10px",
  //                   borderRadius: "5px",
  //                 }}
  //               >
  //                 <Grid
  //                   container
  //                   spacing={2}
  //                   style={{ cursor: "pointer" }}
  //                   onClick={() => {
  //                     // setModal({
  //                     //   open: true,
  //                     //   details: {
  //                     //     cost: iter.details.cost,
  //                     //     insuranceComprehensive:
  //                     //       iter.details.insuranceComprehensive,
  //                     //     location: iter.details.location,
  //                     //     regNo: iter.details.regNo,
  //                     //     insuranceType: iter.insuranceType,
  //                     //     id: iter.id,
  //                     //     insuranceFiles: [
  //                     //       {
  //                     //         name:
  //                     //           iter.policyDoc.indexOf("https://") > -1
  //                     //             ? iter.policyDoc.split("/")[
  //                     //                 iter.policyDoc.split("/").length - 1
  //                     //               ]
  //                     //             : "NA",
  //                     //         url: iter.policyDoc,
  //                     //       },
  //                     //     ],
  //                     //   },
  //                     // });
  //                   }}
  //                 >
  //                   <Grid item xs={4}>
  //                     <div
  //                       style={{
  //                         fontSize: "12px",
  //                         color: "gray",
  //                         fontWeight: 200,
  //                       }}
  //                     >
  //                       Name
  //                     </div>
  //                     <div
  //                       style={{
  //                         fontSize: "12px",
  //                         fontWeight: 200,
  //                       }}
  //                     >
  //                       {iter.name}
  //                     </div>
  //                   </Grid>

  //                   <Grid item xs={4}>
  //                     <div
  //                       style={{
  //                         fontSize: "12px",
  //                         color: "gray",
  //                         fontWeight: 200,
  //                       }}
  //                     >
  //                       Customer Number
  //                     </div>
  //                     <div
  //                       style={{
  //                         fontSize: "12px",
  //                         fontWeight: 200,
  //                       }}
  //                     >
  //                       {iter.customerNo}
  //                     </div>
  //                   </Grid>

  //                   <Grid item xs={4}>
  //                     <div
  //                       style={{
  //                         fontSize: "12px",
  //                         color: "gray",
  //                         fontWeight: 200,
  //                       }}
  //                     >
  //                       Provider Name
  //                     </div>
  //                     <div
  //                       style={{
  //                         fontSize: "12px",
  //                         fontWeight: 200,
  //                       }}
  //                     >
  //                       {iter.provider.name}
  //                     </div>
  //                   </Grid>

  //                   <Grid item xs={4}>
  //                     <div
  //                       style={{
  //                         fontSize: "12px",
  //                         color: "gray",
  //                         fontWeight: 200,
  //                       }}
  //                     >
  //                       Provider Contact
  //                     </div>
  //                     <div
  //                       style={{
  //                         fontSize: "12px",
  //                         fontWeight: 200,
  //                       }}
  //                     >
  //                       {iter.provider.contact}
  //                     </div>
  //                   </Grid>

  //                   <Grid item xs={4}>
  //                     <div
  //                       style={{
  //                         fontSize: "12px",
  //                         color: "gray",
  //                         fontWeight: 200,
  //                       }}
  //                     >
  //                       Provider Email
  //                     </div>
  //                     <div
  //                       style={{
  //                         fontSize: "12px",
  //                         fontWeight: 200,
  //                       }}
  //                     >
  //                       {iter.provider.email}
  //                     </div>
  //                   </Grid>
  //                   <Grid item xs={4}>
  //                     <div
  //                       style={{
  //                         fontSize: "12px",
  //                         color: "gray",
  //                         fontWeight: 200,
  //                       }}
  //                     >
  //                       Provider Website
  //                     </div>
  //                     <div
  //                       style={{
  //                         fontSize: "12px",
  //                         fontWeight: 200,
  //                       }}
  //                     >
  //                       {iter.provider.website}
  //                     </div>
  //                   </Grid>
  //                 </Grid>
  //               </div>
  //             </Grid>
  //           );
  //         })
  //       ) : (
  //         <div
  //           style={{
  //             textAlign: "center",
  //             width: "100%",
  //             padding: "15px",
  //             marginTop: "10px",
  //           }}
  //         >
  //           No Entries Available
  //         </div>
  //       )}
  //     </Grid>
  //   );
  // };

  const optionsDesign = (val, pos) => {
    return (
      <Grid item xs={6} md={4}>
        <div
          style={{
            margin: "10px",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
          // onClick={() => {
          //   setAccordian(pos);
          // }}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "150px",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <div style={{ textAlign: "center" }}>{val.icon}</div>
            <div style={{ textAlign: "center", paddingTop: "5px" }}>
              {val.name}
            </div>
          </div>
        </div>
      </Grid>
    );
  };

  return (
    <div style={{ padding: "5px" }}>
      {/* <Fab
        size="small"
        aria-label="add"
        style={{
          borderRadius: "25px",
          backgroundColor: "#ee17ee",
          color: "white",
          position: "absolute",
          top: "80%",
          left: "90%",
          cursor: "pointer",
          zIndex: 99999999,
        }}
        onClick={() => {
          setModal({
            open: true,
            details: null,
          });
        }}
      >
        <AddIcon />
      </Fab> */}
      <div>
        <ArrowBackIcon
          style={{
            color: "white",
            fontSize: "30px",
            margin: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            props.reset();
          }}
        />{" "}
        <span
          style={{
            fontSize: "25px",
            color: "white",
            position: "relative",
            top: "-15px",
          }}
        >
          {" "}
          Book a service
        </span>
      </div>
      <div style={{ margin: "0px 30px 0px 50px" }}>
        <Grid container spacing={2} style={{ padding: "20px" }}>
          {[
            {
              name: "Cleaner",
              icon: <CleaningServicesIcon />,
            },
            {
              name: "Mover",
              icon: (
                <img
                  src={truck}
                  alt="create_inventory"
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
              ),
            },
            {
              name: "Storage",
              icon: <StorageIcon />,
            },
            {
              name: "Self Move",
              icon: <ShoppingCartCheckoutIcon />,
            },
            {
              name: "Pest Control",
              icon: <PestControlIcon />,
            },
            {
              name: "Skip Bin",
              icon: <CleaningServicesIcon />,
            },
          ].map((iter, pos) => {
            return optionsDesign(iter, pos);
          })}
        </Grid>
      </div>
      {/* {modalFun()} */}
    </div>
  );
};

export default Service;

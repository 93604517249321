export const API_URL = "https://api.removealist.au";

export const LOGIN = "/v1/auth/login";
export const REGISTER = "/v1/auth/register";
export const MOVE_REQUEST = "/v1/move-requests/";
export const UPDATE_MOVE = "/v1/move-requests/modify-move/";
export const UPLOAD = "/v1/upload";
export const GET_WEEKS_LIST = "/v1/tasks/service/{serviceId}/list-tasks";
export const GET_BOXES = "/v1/boxes/service/{serviceId}/get-boxes";
export const UPDATE_BOX = "/v1/boxes/service/{serviceId}/modify-box/";
export const CREATE_BOX = "/v1/boxes/service/{serviceId}/create-box";
export const DELETE_BOX = "/v1/boxes/delete-box/{boxId}";
export const COMPLETE = "/v1/tasks/mark-complete";
export const CHANGE_PASSWORD = "/v1/users/{userId}";
export const USERS = "/v1/users";
export const GET_FIRST_NIGHT_BAGS =
  "/v1/first-night-bags/service/{serviceId}/list-first-night-bag";
export const UPDATE_INSURANCE =
  "/v1/insurances/service/{serviceId}/modify-insurance/";

export const DELETE_INSURANCE = "/v1/insurances/delete-insurance/";
export const UPDATE_FIRST_NIGHT_BAGS =
  "/v1/first-night-bags/service/{serviceId}/mark-complete/{itemId}";
export const GET_USER_INSURANCES = "/v1/insurances/fetch-insurance";
export const GET_ALL_PETS = "/v1/pets/get-all-pet";
export const CREATE_PET = "/v1/pets/service/{serviceId}/create-pet";
export const CREATE_INSURANCE =
  "/v1/insurances/service/{serviceId}/create-insurance";
export const DELETE_PET = "/v1/pets/delete-pet/";
export const UPDATE_PET = "/v1/pets/service/{serviceId}/modify-pet/";
export const LIST_UTILS = "/v1/utilities/service/{serviceId}/list-utilities";
export const ADD_UTILITY = "/v1/utilities/service/{serviceId}/add-utility";
export const DELETE_UTILITY = "/v1/utilities/delete-utility/";
export const UPDATE_UTILS = "/v1/utilities/service/{serviceId}/modify-utility/";
export const GET_ALL_GARAGES = "/v1/catalog/garage-sale/";

export const API_KEY = "AIzaSyCr95eWfzoB1mJiSkD8Zmz1rlThkgyhil0";
// "AIzaSyAGTurEqlQ9tXkGW8FHuhr1QVd9e9ICZkU";

import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import { invokeApi } from "./api/index";
import Avatar from "@mui/material/Avatar";
import { API_URL, MOVE_REQUEST } from "./api/constant";
import moment from "moment";
// import { makeStyles } from "@material-ui/core/styles";
// import ToggleButton from "@mui/material/ToggleButton";
// import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
// const label = { inputProps: { "aria-label": "Switch demo" } };
// const useStyles = makeStyles((theme) => ({
//   ToggleButton: {
//     padding: "7px",
//     borderRadius: "3.5px",
//     "&.MuiToggleButton-root.Mui-selected": {
//       backgroundColor: "black",
//     },
//   },
// }));

const MyBooking = (props) => {
  // const classes = useStyles();
  const history = useNavigate();
  // const [toggle, setToggle] = useState("1");
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    invokeApi("GET", `${API_URL}${MOVE_REQUEST}` + "get-recent-move")
      .then((response) => {
        if (response.id) {
          let array = [];
          array.push({
            id: response.id,
            name: "Moving",
            date: response.movingDate,
            status:
              response.status == "Active" ? "Request In-progress" : "Completed",
            active: response.status == "Active" ? true : false,
          });
          setBookings(array);
        }
      })
      .catch((err) => {
        console.logr("Fetching booking failed", err);
      });
  }, []);

  const newBooking = () => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10%",
            marginTop: "10%",
          }}
        >
          <div style={{ color: "white" }}>
            You don't have any active Service right now.
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            style={{
              borderRadius: "5px",
              color: "white",
              fontWeight: "bold",
              textTransform: "none",
              backgroundColor: "black",
            }}
            onClick={() => {
              history("/moving");
            }}
          >
            Book a Service
          </Button>
        </div>
      </div>
    );
  };

  const bookingCard = (val) => {
    return (
      <div style={{ display: "contents", maxWidth: "90%", overflow: "auto" }}>
        {val.map((iter) => {
          return (
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "10px",
                margin: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "350px",
                  backgroundColor: "#0000001f",
                  borderRadius: "10px 10px 0px 0px",
                  padding: "10px",
                }}
              >
                <div style={{ width: "50%" }}>
                  <div style={{ fontSize: "15px" }}>{iter.name}</div>
                  <div
                    style={{
                      fontSize: "10px",
                      color: "gray",
                      marginTop: "5px",
                    }}
                  >
                    {moment(iter.date).local().format("MMM, DD, yyyy")}
                  </div>
                </div>

                <div style={{ marginLeft: "auto" }}>
                  <span
                    style={{
                      padding: "5px",
                      color: "white",
                      backgroundColor: "green",
                      borderRadius: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {iter.status}
                  </span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <Avatar
                    alt={"User"}
                    src="/static/images/avatar/2.jpg"
                    style={{
                      width: "75px",
                      height: "75px",
                      margin: "20px",
                      color: "white",
                      backgroundColor: "gray",
                    }}
                  />
                </div>
                <div style={{ lineHeight: "100px" }}>
                  <span
                    style={{
                      fontSize: "15px",
                      verticalAlign: "middle",
                    }}
                  >
                    {iter.status}
                  </span>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "black",
                  color: "white",
                  textAlign: "center",
                  padding: "10px",
                  cursor: "pointer",
                }}
              >
                <span
                  onClick={() => {
                    history("/tasks", {
                      state: { id: iter.id, date: iter.date },
                    });
                  }}
                >
                  {" "}
                  View Service{" "}
                </span>
                |{" "}
                <span
                  onClick={() => {
                    history("/moving", {
                      state: { iter: iter },
                    });
                    // history("/tasks", {
                    //   state: { id: iter.id, date: iter.date },
                    // });
                  }}
                >
                  Edit Service{" "}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  // const onGoingFun = () => {
  //   let onGoingService = bookings.filter((iter) => iter.active == true);
  //   return (
  //     <div>
  //       {onGoingService.length > 0 ? bookingCard(onGoingService) : newBooking()}
  //     </div>
  //   );
  // };

  const historyFun = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {bookings.length > 0 ? bookingCard(bookings) : newBooking()}
      </div>
    );
  };

  return (
    <div style={{ padding: "5px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            fontSize: "20px",
            color: "white",
          }}
        >
          My Bookings
        </div>
      </div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={toggle}
          exclusive
          style={{ backgroundColor: "white" }}
          onChange={(e, val) => {
            setToggle(val);
          }}
        >
          <ToggleButton value="0" className={classes.ToggleButton}>
            On Going
          </ToggleButton>
          <ToggleButton value="1" className={classes.ToggleButton}>
            History
          </ToggleButton>
        </ToggleButtonGroup>
      </div> */}
      <div style={{ marginTop: "30px" }}>
        {/* {toggle == "0" ? onGoingFun() :  */}
        {historyFun()}
        {/* } */}
      </div>
    </div>
  );
};

export default MyBooking;

import * as React from "react";
import { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { invokeApi } from "./api/index";
import { useLocation } from "react-router-dom";
import { API_URL, GET_WEEKS_LIST, COMPLETE } from "./api/constant";
import { toast } from "react-toastify";
import moment from "moment";
import PetsRoundedIcon from "@mui/icons-material/PetsRounded";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Boxes from "./Boxes";
import Insurance from "./Insurance";
import Pets from "./Pets";
import Utilities from "./Utilities";
import Service from "./Service";
import FirstNightBag from "./FirstNightBag";
import Address from "./Address";
import Inventory from "./Inventory";
import { styled } from "@mui/material/styles";
import boxes from "../resources/icons/boxes.png";
import create_inventory from "../resources/icons/create_inventory.png";
import address_change from "../resources/icons/address_change.png";
import book_a_service from "../resources/icons/book_a_service.png";
import insurance from "../resources/icons/insurance.png";
import first_night_bag from "../resources/icons/first_night_bag.png";
import HandymanIcon from "@mui/icons-material/Handyman";
import InventoryIcon from "@mui/icons-material/Inventory";
import removeAList from "../resources/icons/remove_a_list.png";
import DangerousGoods from "./DangerousGoods";
import GarageSale from "./GarageSale";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  margin: "10px",
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const Tasks = (props) => {
  const location = useLocation();
  const [accordian, setAccordian] = useState(null);
  const [taskList, setTaskList] = useState([]);
  const [title, setTitle] = useState({
    name: null,
    task: [],
  });
  // const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    let serviceId = location.state.id;
    invokeApi(
      "GET",
      `${API_URL}${GET_WEEKS_LIST.replace("{serviceId}", serviceId)}`
    )
      .then((response) => {
        setTaskList(response);
      })
      .catch((err) => {
        toast.error("Fetching Task details failed");
      });
  }, []);

  const onCompleteChange = (task) => {
    invokeApi("PUT", `${API_URL}${COMPLETE}/${task.id}`, {
      completed: !task.completed,
    })
      .then((response) => {
        if (response.serviceId) {
          let taskListTemp = taskList.map((iter) => {
            if (iter.id == task.id) {
              return response;
            } else {
              return iter;
            }
          });
          let titleTemp = title.task.map((iter) => {
            if (iter.id == task.id) {
              return response;
            } else {
              return iter;
            }
          });
          setTitle({ ...title, task: titleTemp });
          setTaskList(taskListTemp);
          // setRefresh(Math.random());
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const optionsDesign = (val, pos) => {
    return (
      <Grid item xs={6} md={4}>
        <div
          style={{
            margin: "10px",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            setAccordian(pos);
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "150px",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <div style={{ textAlign: "center" }}>{val.icon}</div>
            <div style={{ textAlign: "center" }}>{val.name}</div>
          </div>
        </div>
      </Grid>
    );
  };

  const optionsFun = () => {
    return (
      <>
        <Grid container spacing={2} style={{ padding: "20px" }}>
          {[
            {
              name: "Boxes",
              icon: (
                <img
                  src={boxes}
                  alt="boxes"
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
              ),
            },
            {
              name: "Create Inventory",
              icon: (
                <img
                  src={create_inventory}
                  alt="create_inventory"
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
              ),
            },
            {
              name: "Address Change",
              icon: (
                <img
                  src={address_change}
                  alt="address_change"
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
              ),
            },
            {
              name: "Book a Services",
              icon: (
                <img
                  src={book_a_service}
                  alt="book_a_service"
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
              ),
            },
            {
              name: "Insurance",
              icon: (
                <img
                  src={insurance}
                  alt="insurance"
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
              ),
            },
            {
              name: "Pets",
              icon: <PetsRoundedIcon />,
            },

            {
              name: "First Night Bag",
              icon: (
                <img
                  src={first_night_bag}
                  alt="first_night_bag"
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
              ),
            },

            {
              name: "Dangerous Goods",
              icon: <InventoryIcon />,
            },

            {
              name: "Garage Sale",
              icon: (
                <img
                  src={removeAList}
                  alt="garage_sale"
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
              ),
            },
            {
              name: "Utilities",
              icon: <HandymanIcon />,
            },
          ].map((iter, pos) => {
            return optionsDesign(iter, pos);
          })}
        </Grid>
      </>
    );
  };

  const taskFilter = (val) => {
    let taskArray = [];
    switch (val) {
      case "8":
        taskArray = taskList.filter((i) => {
          return i.weekNo == val;
        });
        break;
      case "6":
        taskArray = taskList.filter((i) => {
          return i.weekNo == val;
        });
        break;
      case "4":
        taskArray = taskList.filter((i) => {
          return i.weekNo == val;
        });
        break;
      case "2":
        taskArray = taskList.filter((i) => {
          return i.weekNo == val;
        });
        break;
      default:
        taskArray = taskList.filter((i) => {
          return i.weekNo == "1" || i.weekNo == "0";
        });
        break;
    }
    return taskArray;
  };

  const setTitleFun = (weekNo, val) => {
    if (title.name != val) {
      setTitle({ task: taskFilter(weekNo), name: val });
    } else {
      setTitle({ name: null, task: [] });
    }
  };

  const reset = () => {
    setAccordian(null);
  };

  const optionsDetailsfun = (val) => {
    switch (val) {
      case 0:
        return <Boxes reset={reset} />;
      case 1:
        return <Inventory reset={reset} />;
      case 2:
        return <Address reset={reset} />;
      case 3:
        return <Service reset={reset} />;
      case 4:
        return <Insurance reset={reset} />;
      case 5:
        return <Pets reset={reset} />;
      case 6:
        return <FirstNightBag reset={reset} />;
      case 7:
        return <DangerousGoods reset={reset} />;
      case 8:
        return <GarageSale reset={reset} />;
      default:
        return <Utilities reset={reset} />;
    }
  };

  const moveDetails = () => {
    let diffTime = Math.abs(
      moment(location.state.date).local().valueOf() - moment().valueOf()
    );
    let days = Math.ceil(diffTime / (24 * 60 * 60 * 1000));
    let week = parseInt(days / 7);
    let day = days % 7;
    let remaining = week + " weeks " + day + " days";
    return (
      <div>
        <div style={{ diaplay: "flex", flexGrow: "row" }}>
          <Button
            variant="contained"
            style={{
              width: "100px",
              borderRadius: "10px",
              color: title.name == "8 Weeks" ? "white" : "black",
              textTransform: "none",
              backgroundColor: title.name == "8 Weeks" ? "#0fcbd0" : "white",
              margin: "20px",
            }}
            fullWidth
            onClick={(e) => {
              setTitleFun("8", "8 Weeks");
            }}
          >
            8 Weeks
          </Button>
          <Button
            variant="contained"
            style={{
              width: "100px",
              borderRadius: "10px",
              color: title.name == "6 Weeks" ? "white" : "black",
              textTransform: "none",
              backgroundColor: title.name == "6 Weeks" ? "#0fcbd0" : "white",
              margin: "20px",
            }}
            fullWidth
            onClick={(e) => {
              setTitleFun("6", "6 Weeks");
            }}
          >
            6 Weeks
          </Button>
          <Button
            variant="contained"
            style={{
              width: "100px",
              borderRadius: "10px",
              color: title.name == "4 Weeks" ? "white" : "black",
              textTransform: "none",
              backgroundColor: title.name == "4 Weeks" ? "#0fcbd0" : "white",
              margin: "20px",
            }}
            fullWidth
            onClick={(e) => {
              setTitleFun("4", "4 Weeks");
            }}
          >
            4 Weeks
          </Button>
          <Button
            variant="contained"
            style={{
              width: "100px",
              borderRadius: "10px",
              color: title.name == "2 Weeks" ? "white" : "black",
              textTransform: "none",
              backgroundColor: title.name == "2 Weeks" ? "#0fcbd0" : "white",
              margin: "20px",
            }}
            fullWidth
            onClick={(e) => {
              setTitleFun("2", "2 Weeks");
            }}
          >
            2 Weeks
          </Button>
          <Button
            variant="contained"
            style={{
              width: "100px",
              borderRadius: "10px",
              color: title.name == "Move Day" ? "white" : "black",
              textTransform: "none",
              backgroundColor: title.name == "Move Day" ? "#0fcbd0" : "white",
              margin: "20px",
            }}
            fullWidth
            onClick={(e) => {
              setTitleFun("0", "Move Day");
            }}
          >
            Move Day
          </Button>
        </div>
        <div style={{ color: "white", fontSize: "15px", marginLeft: "20px" }}>
          Time Remaining: {remaining}
        </div>
        {title.name == null ? (
          accordian == null ? (
            optionsFun()
          ) : (
            optionsDetailsfun(accordian)
          )
        ) : (
          <div
            style={{
              backgroundColor: "#00000014",
              borderRadius: "20px",
              // margin: "0px 20px 20px 20px ",
              padding: "10px",
              height: "75vh",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "20px",
                margin: "10px ",
                padding: "10px",
                height: "13vh",
              }}
            >
              <div
                style={{
                  borderBottom: "1px dashed black",
                  height: "20px",
                  display: "flex",
                  padding: "20px",
                }}
              >
                <div>{title.name}</div>
                <div style={{ marginLeft: "auto", fontWeight: 200 }}>
                  {title.task.filter((iter) => iter.completed).length}/
                  {title.task.length} Completed
                </div>
              </div>
              <div style={{ padding: "10px 20px 20px 20px" }}>Tasks</div>
            </div>
            <div style={{ padding: "15px", height: "50vh", overflow: "auto" }}>
              {title.task.length > 0
                ? title.task.map((iter, pos) => {
                    return (
                      <div
                        style={{
                          margin: "10px 20px 10px 20px",
                          height: "60px",
                          borderBottom:
                            pos == title.task.length - 1
                              ? "none"
                              : "1px dashed white",
                        }}
                      >
                        <BpCheckbox
                          checked={iter.completed}
                          onChange={() => {
                            onCompleteChange(iter);
                          }}
                        />{" "}
                        <span
                          style={{
                            textDecoration: iter.completed
                              ? "line-through"
                              : "none",
                            fontWeight: 200,
                            color: "white",
                          }}
                        >
                          {iter.description}
                        </span>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        )}
      </div>
    );
  };

  return <div style={{ padding: "5px" }}>{moveDetails()}</div>;
};

export default Tasks;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import rapid from "../../resources/icons/rapid-logo.png";

const ForgotPassword = () => {
  const [loginDetails, setLoginDetails] = useState({
    email: "",
  });
  const history = useNavigate();

  useEffect(() => {
    document.addEventListener("keydown", handleKeyEvent);
    return () => {
      document.removeEventListener("keydown", handleKeyEvent);
    };
  }, []);

  const handleKeyEvent = (event) => {
    if (event.keyCode === 13) {
      onSubmit();
    }
  };

  const onInputChange = (event) => {
    setLoginDetails({
      ...loginDetails,
      [event.target.name]: event.target.value,
      [event.target.name + "Err"]: false,
    });
  };

  const isFormValid = () => {
    let errors = {};

    if (!loginDetails.email) {
      errors["emailErr"] = true;
    }

    if (errors.nameErr) {
      setLoginDetails({ ...loginDetails, ...errors });
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    if (isFormValid()) {
      let payload = {
        userName: loginDetails.userName.trim(),
        password: loginDetails.password,
      };
      history("/home");
      // invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${API.LOGIN}`, payload)
      //   .then(response => {
      //     if (response.message) {
      //       toast.error(response.message);
      //       setLoginDetails({
      //         ...loginDetails,
      //         errorMsg: response.message
      //       });
      //     } else {
      //       response.user && setOfflineData("user", response.user);
      //       response.tokens && setOfflineData("tokens", response.tokens);

      //       let navUrl =`
      //         (response &&
      //           response.user.role &&
      //           ROLE_NAV[response.user.role.name]) ||
      //         "/users";
      //       history(navUrl);
      //       setActiveRoute(navUrl);
      //     }
      //   })
      //   .catch(err => {
      //     if (err.response && err.response.data && err.response.data.message) {
      //       setLoginDetails({
      //         ...loginDetails,
      //         errorMsg: err.response.data.message
      //       });
      //     }
      //     console.log("err", err);
      //   });
    }
  };

  return (
    <div className={"login"}>
      <Grid container>
        <Grid item md={4} xs={12} className={"loginBanner"}>
          <div
            style={{
              position: "relative",
              top: "20%",
              color: "white",
              fontSize: "50px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <img
              src={rapid}
              alt="Rapid"
              style={{ width: "150px", height: "50px" }}
            />
          </div>
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          style={{
            padding: "50px",
            paddingRight: "80px",
            backgroundColor: "#732727",
            borderRadius: "0px 50px 50px 0px",
          }}
        >
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Reset Link
          </div>
          <div
            style={{
              fontSize: "10px",
              marginBottom: "10px",
            }}
          >
            Enter your email, and we will send the link.
          </div>
          <div>
            <TextField
              name="email"
              onChange={onInputChange}
              color="primary"
              placeholder="Email"
              value={loginDetails.email}
              variant="standard"
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "5px 5px 5px 10px",
                margin: "10px 0px 10px 0px",
                width: "100%",
                border: loginDetails.emailErr ? "1px solid red" : "",
              }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonOutlinedIcon style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div
            style={{
              textAlign: "center",
              margin: "20px 0px 5px 0px",
              width: "104%",
            }}
          >
            <Button
              variant="contained"
              style={{
                width: "40%",
                borderRadius: "20px",
                color: "black",
                backgroundColor: "white",
                fontWeight: "bold",
                textTransform: "none",
                // backgroundImage:
                //   "linear-gradient(90deg, rgb(243, 194, 203), rgb(176, 191, 245), rgb(171, 238, 171))",
              }}
              onClick={onSubmit}
            >
              Send Reset Link
            </Button>
          </div>
          <div>
            {loginDetails.errorMsg && (
              <span className="error-msg">{loginDetails.errorMsg}</span>
            )}
          </div>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <div
              style={{
                fontWeight: 200,
                padding: "5px",
                cursor: "pointer",
                width: "104%",
              }}
              onClick={() => {
                history("/login");
              }}
            >
              <LockOutlinedIcon
                style={{ color: "gray", position: "relative", top: "2px" }}
              />
              <span style={{ position: "relative", top: "-4px", left: "10px" }}>
                Return to Login Page
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgotPassword;

import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "../index.scss";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@material-ui/styles";
import SideMenu from "../components/side-nav";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Avatar from "@mui/material/Avatar";
import Home from "../components/Home";
import Moving from "../components/Moving";
import Tasks from "../components/Tasks";
import MyBooking from "../components/MyBooking";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

import { useNavigate } from "react-router-dom";
import removeAList from "../resources/icons/remove_a_list.png";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: "2px",
    right: "2px",
    fontSize: "0.5rem",
    minWidth: "12px",
    padding: "2px",
    height: "12px",
  },
}));

export const LandingPage = (props) => {
  const history = useNavigate();
  const [headerName, setHeaderName] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const user = JSON.parse(localStorage.getItem("user"))?.user;
  // const headerNameFun = (name) => {
  //   setHeaderName(name);
  // };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const activeMenuFun = (val) => {
  //   switch (val) {
  //     case "Profile":
  //       return 2;
  //     case "More":
  //       return 3;
  //     case "Moving":
  //       return 11;
  //     case "Other Service":
  //       return 14;
  //     case "Renovation":
  //       return 13;
  //     case "Senior Transitions":
  //       return 12;
  //     // case "Real Time Graphs":
  //     //   return 34;
  //     // case "Remote Access":
  //     //   return 4;
  //     // case "Updates":
  //     //   return 5;
  //     // case "Rollout":
  //     //   return 51;
  //     // case "Distributions":
  //     //   return 52;
  //     // case "Setting":
  //     //   return 6;
  //     default:
  //       return 1;
  //   }
  // };

  // const hrefFun = (val) => {
  //   switch (val) {
  //     case "profile":
  //       setHeaderName("Profile");
  //       break;
  //     case "more":
  //       setHeaderName("More");
  //       break;
  //     case "moving":
  //       setHeaderName("Moving");
  //       break;
  //     case "other-service":
  //       setHeaderName("Other Service");
  //       break;
  //     case "renovation":
  //       setHeaderName("Renovation");
  //       break;
  //     case "senior-transitioning":
  //       setHeaderName("Senior Transitions");
  //       break;
  //     default:
  //       setHeaderName("Home");
  //       break;
  //   }
  // };

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user?.tokens?.access?.token) {
      // hrefFun(
      //   window.location.href.split("/")[
      //     window.location.href.split("/").length - 1
      //   ]
      // );
    } else {
      history("/login");
    }
  }, [window.location.href]);

  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            height: "50px",
          }}
        >
          <div
            style={{
              fontSize: "24px",
              padding: "7px 7px 7px 20px",
              cursor: "pointer",
            }}
            onClick={() => {
              history("/home");
            }}
          >
            <img
              src={removeAList}
              alt="removeAList"
              style={{
                width: "50px",
                height: "40px",
                position: "absolute",
                cursor: "pointer",
              }}
            />
            <div
              style={{ marginLeft: "60px", paddingTop: "5px", color: "white" }}
            >
              RemoveAlist
            </div>
          </div>
          <div style={{ display: "flex", marginLeft: "auto" }}>
            <span
              style={{
                position: "relative",
                top: "10px",
              }}
            >
              <QrCodeScannerIcon
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  padding: "5px",
                }}
              />
            </span>
            <span
              style={{
                cursor: "pointer",
                color: "white",
                position: "relative",
                top: "15px",
                margin: "0px 30px 0px 30px",
              }}
              onClick={() => {
                history("/my-bookings");
              }}
            >
              My Bookings
            </span>
            {/* <StyledBadge
              badgeContent={4}
              color="primary"
              style={{
                position: "relative",
                top: "10px",
                left: "-15px",
              }}
            >
              <NotificationsIcon
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  padding: "5px",
                }}
              />
            </StyledBadge> */}
            {/* <Avatar
              alt={user?.name}
              src="/static/images/avatar/2.jpg"
              style={{
                width: "30px",
                height: "30px",
                position: "relative",
                top: "10px",
                color: "black",
                backgroundColor: "white",
              }}
            /> */}
            <span
              style={{
                cursor: "pointer",
                color: "white",
                position: "relative",
                top: "15px",
                padding: "0px 10px 0px 10px",
              }}
              onClick={handleClick}
            >
              {user?.name}
            </span>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem style={{ display: "inherit" }} onClick={handleClose}>
                Profile
              </MenuItem>
              <MenuItem style={{ display: "inherit" }} onClick={handleClose}>
                My account
              </MenuItem>
              <MenuItem
                style={{ display: "inherit" }}
                onClick={() => {
                  localStorage.removeItem("user");
                  history("/login");
                  handleClose();
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div>
          <Routes>
            <Route exact path="/" element={<Home {...props} />} />
            <Route path="/home" element={<Home {...props} />} />
            <Route path="/moving" element={<Moving {...props} />} />
            <Route
              path="/senior-transitioning"
              element={
                <div
                  style={{ padding: "40px", fontSize: "40px", color: "white" }}
                >
                  Senior Transitioning
                </div>
              }
            />
            <Route
              path="/renovation"
              element={
                <div
                  style={{ padding: "40px", fontSize: "40px", color: "white" }}
                >
                  Renovation
                </div>
              }
            />
            <Route
              path="/other-service"
              element={
                <div
                  style={{ padding: "40px", fontSize: "40px", color: "white" }}
                >
                  Other Services
                </div>
              }
            />

            <Route path="/my-bookings" element={<MyBooking {...props} />} />
            <Route path="/tasks" element={<Tasks {...props} />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

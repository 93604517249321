import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { API_URL, GET_ALL_GARAGES } from "../components/api/constant";
import Grid from "@mui/material/Grid";
import { invokeApi } from "./api/index";
import EditIcon from "@mui/icons-material/Edit";
import { TextField, Button } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { toast } from "react-toastify";
import moment from "moment";

const GarageSale = (props) => {
  const [garageList, setGarageList] = useState([]);
  const [modal, setModal] = React.useState({
    open: false,
    details: null,
  });
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const getCall = () => {
    let serviceId = location.state.id;
    invokeApi("GET", `${API_URL}${GET_ALL_GARAGES}` + serviceId)
      .then((response) => {
        let array = [];
        array.push(response.gsale);
        setGarageList(array);
        setModal({
          open: false,
          details: null,
        });
        setLoading(false);
      })
      .catch((err) => {
        setModal({
          open: false,
          details: null,
        });
        setLoading(false);
        console.log("Fetching Utilities details failed");
      });
  };

  useEffect(() => {
    getCall();
  }, []);

  const modalFun = () => {
    return (
      <Modal
        disableEnforceFocus={true}
        open={modal.open == true ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setModal({
              open: false,
              details: null,
            });
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box className="createBox" style={{ p: 4 }}>
            <div
              style={{
                textAlign: "center",
                padding: "0px 0px 20px 0px",
                fontSize: "25px",
              }}
            >
              {modal.details == null
                ? "Create Garage Sale"
                : "Update  Garage Sale"}
            </div>
            <Grid container spacing={2} style={{}}>
              <Grid item xs={12} md={3}>
                <div>Location</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="location"
                  className={
                    modal.details?.type < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: { ...modal.details, location: e.target.value },
                    });
                  }}
                  color="primary"
                  placeholder="Location"
                  value={modal.details?.location}
                  variant="standard"
                  style={{ width: "75%" }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <div>Move Date</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    required
                    value={modal.details?.time}
                    onChange={(e) => {
                      setModal({
                        ...modal,
                        details: { ...modal.details, time: e },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} style={{ width: "75%" }} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  margin: "10px",
                }}
                onClick={() => {
                  let serviceId = location.state.id;
                  let payload = {};
                  payload["title"] = "Garage sales";
                  payload["location"] = modal.details.location;
                  payload["time"] = new Date(modal.details.time).toISOString();
                  if (modal.details.id == undefined) {
                    setLoading(true);
                    invokeApi(
                      "POST",
                      `${API_URL}${GET_ALL_GARAGES}` + serviceId,
                      payload
                    )
                      .then((res) => {
                        if (res.id) {
                          getCall();
                        }
                      })
                      .catch((err) => {
                        toast.error("Garage sale creation failed");
                        setLoading(false);
                      });
                  } else {
                    setLoading(true);
                    invokeApi(
                      "PUT",
                      `${API_URL}${GET_ALL_GARAGES}` +
                        serviceId +
                        `/` +
                        modal.details.id,
                      payload
                    )
                      .then((res) => {
                        if (res.id) {
                          getCall();
                        }
                      })
                      .catch((err) => {
                        toast.error("Garage sale updation failed");
                        setLoading(false);
                      });
                  }
                }}
              >
                Save
              </Button>

              <Button
                variant="contained"
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  margin: "10px",
                }}
                onClick={() => {
                  setModal({
                    open: false,
                    details: null,
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </>
      </Modal>
    );
  };

  return (
    <div style={{ padding: "5px" }}>
      <div>
        <ArrowBackIcon
          style={{
            color: "white",
            fontSize: "30px",
            margin: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            props.reset();
          }}
        />{" "}
        <span
          style={{
            fontSize: "25px",
            color: "white",
            position: "relative",
            top: "-15px",
          }}
        >
          {" "}
          Garage Sale
        </span>
      </div>
      <div style={{ margin: "0px 30px 0px 50px" }}>
        <Grid container spacing={2} style={{ padding: "20px" }}>
          {garageList.length > 0 ? (
            garageList.map((iter, pos) => {
              let diffTime = Math.abs(
                moment(iter.time).local().valueOf() - moment().valueOf()
              );
              let days = Math.ceil(diffTime / (24 * 60 * 60 * 1000));
              let remaining = days + " days";

              return (
                <Grid item xs={6} md={4}>
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "20px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      display: "flex",
                    }}
                  >
                    <Grid container spacing={2} style={{ padding: "5px" }}>
                      <Grid item xs={3}>
                        Location :
                      </Grid>
                      <Grid item xs={9}>
                        {iter.location}
                      </Grid>
                      <Grid item xs={3}>
                        Days Left :{" "}
                      </Grid>
                      <Grid item xs={9}>
                        {remaining}
                      </Grid>
                    </Grid>
                    <EditIcon
                      style={{
                        position: "relative",
                        top: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setModal({
                          open: true,
                          details: iter,
                        });
                      }}
                    />
                  </div>
                </Grid>
              );
            })
          ) : (
            <center>
              <Button
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                  // backgroundColor: "white",
                  //  color: "black",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  margin: "10px",
                }}
                onClick={() => {
                  setModal({
                    open: true,
                    details: null,
                  });
                }}
              >
                Create Garage Sale
              </Button>
            </center>
          )}
        </Grid>
      </div>
      {modalFun()}
    </div>
  );
};

export default GarageSale;

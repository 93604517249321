import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  API_URL,
  LIST_UTILS,
  ADD_UTILITY,
  DELETE_UTILITY,
  UPDATE_UTILS,
} from "../components/api/constant";
import { toast } from "react-toastify";
import { invokeApi } from "./api/index";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { TextField, Button } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const Utilities = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [utilityList, setUtilityList] = useState({});
  const [modal, setModal] = React.useState({
    open: false,
    details: null,
  });
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getCall = () => {
    let serviceId = location.state.id;
    invokeApi(
      "GET",
      `${API_URL}${LIST_UTILS.replace("{serviceId}", serviceId)}`
    )
      .then((response) => {
        let obj = {};
        response.forEach((iter) => {
          let array = obj[iter.type] ? obj[iter.type] : [];
          array.push(iter);
          obj[iter.type] = array;
        });
        setUtilityList(obj);
        setModal({
          open: false,
          details: null,
        });
        setLoading(false);
      })
      .catch((err) => {
        setModal({
          open: false,
          details: null,
        });
        setLoading(false);
        console.log("Fetching Utilities details failed");
      });
  };

  const modalFun = () => {
    return (
      <Modal
        disableEnforceFocus={true}
        open={modal.open == true ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setModal({
              open: false,
              details: null,
            });
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box className="createBox" style={{ p: 4, top: "50%" }}>
            <div
              style={{
                textAlign: "center",
                padding: "0px 0px 20px 0px",
                fontSize: "25px",
              }}
            >
              {modal.details == null ? "Create A Utility" : "Update Utility"}
            </div>
            <Grid container spacing={2} style={{}}>
              <Grid item xs={12} md={3}>
                <div>Type</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="type"
                  className={
                    modal.details?.type < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: { ...modal.details, type: e.target.value },
                    });
                  }}
                  color="primary"
                  placeholder="Type"
                  value={modal.details?.type}
                  variant="standard"
                  style={{ width: "75%" }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <div>Name</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="name"
                  className={
                    modal.details?.name < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: {
                        ...modal.details,
                        name: e.target.value,
                      },
                    });
                  }}
                  color="primary"
                  placeholder="Name"
                  value={modal.details?.name}
                  style={{ width: "75%" }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <div>Customer Number</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="name"
                  className={
                    modal.details?.customerNo < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: {
                        ...modal.details,
                        customerNo: e.target.value,
                      },
                    });
                  }}
                  color="primary"
                  placeholder="Customer Number"
                  value={modal.details?.customerNo}
                  style={{ width: "75%" }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <div>Provider Name</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="name"
                  className={
                    modal.details?.provider?.name < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: {
                        ...modal.details,
                        provider: {
                          ...modal.details?.provider,
                          name: e.target.value,
                        },
                      },
                    });
                  }}
                  color="primary"
                  placeholder="Provider Name"
                  value={modal.details?.provider?.name}
                  style={{ width: "75%" }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <div>Provider Contact</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="name"
                  className={
                    modal.details?.provider?.contact < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: {
                        ...modal.details,
                        provider: {
                          ...modal.details?.provider,
                          contact: e.target.value,
                        },
                      },
                    });
                  }}
                  color="primary"
                  placeholder="Provider Contact"
                  value={modal.details?.provider?.contact}
                  style={{ width: "75%" }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <div>Provider Email</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="name"
                  className={
                    modal.details?.provider?.email < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: {
                        ...modal.details,
                        provider: {
                          ...modal.details?.provider,
                          email: e.target.value,
                        },
                      },
                    });
                  }}
                  color="primary"
                  placeholder="Provider Email"
                  value={modal.details?.provider?.email}
                  style={{ width: "75%" }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <div>Provider Website</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="name"
                  className={
                    modal.details?.provider?.website < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: {
                        ...modal.details,
                        provider: {
                          ...modal.details?.provider,
                          website: e.target.value,
                        },
                      },
                    });
                  }}
                  color="primary"
                  placeholder="Provider Website"
                  value={modal.details?.provider?.website}
                  style={{ width: "75%" }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>
            </Grid>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  margin: "10px",
                }}
                onClick={() => {
                  let serviceId = location.state.id;
                  let payload = {};
                  if (modal.details.id == undefined) {
                    payload["type"] = modal.details.type;
                    payload["name"] = modal.details.name;
                    payload["customerNo"] = modal.details.customerNo;
                    payload["provider"] = {
                      name: modal.details?.provider.name,
                      contact: modal.details?.provider.contact,
                      email: modal.details?.provider.email,
                      website: modal.details?.provider.website,
                    };
                    setLoading(true);
                    invokeApi(
                      "POST",
                      `${API_URL}${ADD_UTILITY.replace(
                        "{serviceId}",
                        serviceId
                      )}`,
                      payload
                    )
                      .then((res) => {
                        if (res.id) {
                          getCall();
                        }
                      })
                      .catch((err) => {
                        toast.error("Utility creation failed");
                        setLoading(false);
                      });
                  } else {
                    alert("in-progress");
                    setModal({
                      open: false,
                      details: null,
                    });
                    setLoading(false);
                    // payload["type"] = modal.details.type;
                    // payload["title"] = modal.details.title;
                    // payload["description"] = modal.details.description;
                    // payload["count"] = modal.details.count;
                    // payload["archive"] = modal.details.archive;

                    // invokeApi(
                    //   "PUT",
                    //   `${API_URL}${UPDATE_BOX.replace(
                    //     "{serviceId}",
                    //     serviceId
                    //   )}` + modal.details.id,
                    //   payload
                    // )
                    //   .then((res) => {
                    //     if (res.id) {
                    //       let updatedList = list.map((iter) => {
                    //         if (iter.id == res.id) {
                    //           return res;
                    //         } else {
                    //           return iter;
                    //         }
                    //       });
                    //       setBoxList(updatedList);
                    //       setModal({
                    //         open: false,
                    //         details: null,
                    //       });
                    //       setLoading(false);
                    //     }
                    //   })
                    //   .catch((err) => {
                    //     toast.error("Box updation failed");
                    //     setLoading(false);
                    //   });
                  }
                }}
              >
                Save
              </Button>
              {modal.details?.id !== undefined && (
                <Button
                  variant="contained"
                  style={{
                    textTransform: "none",
                    backgroundColor: "white",
                    color: "black",
                    fontWeight: "bold",
                    borderRadius: "10px",
                    margin: "10px",
                  }}
                  onClick={() => {
                    invokeApi(
                      "DELETE",
                      API_URL + DELETE_UTILITY + modal.details.id
                    )
                      .then((response) => {
                        getCall();
                      })
                      .catch((error) => {});
                  }}
                >
                  Delete
                </Button>
              )}

              <Button
                variant="contained"
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  margin: "10px",
                }}
                onClick={() => {
                  setModal({
                    open: false,
                    details: null,
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </>
      </Modal>
    );
  };

  useEffect(() => {
    getCall();
  }, []);

  const accordianDetailsFun = (val, iterName) => {
    return (
      <Grid container spacing={2}>
        {val.length > 0 ? (
          val.map((iter, index) => {
            return (
              <Grid item xs={12} md={6}>
                <div
                  style={{
                    backgroundColor: "#e3e0df",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setModal({
                        open: true,
                        location: {
                          obj: iterName,
                          index: index,
                        },
                        details: { ...iter },
                      });
                    }}
                  >
                    <Grid item xs={4}>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          fontWeight: 200,
                        }}
                      >
                        Name
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 200,
                        }}
                      >
                        {iter.name}
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          fontWeight: 200,
                        }}
                      >
                        Customer Number
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 200,
                        }}
                      >
                        {iter.customerNo}
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          fontWeight: 200,
                        }}
                      >
                        Provider Name
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 200,
                        }}
                      >
                        {iter.provider.name}
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          fontWeight: 200,
                        }}
                      >
                        Provider Contact
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 200,
                        }}
                      >
                        {iter.provider.contact}
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          fontWeight: 200,
                        }}
                      >
                        Provider Email
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 200,
                        }}
                      >
                        {iter.provider.email}
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          fontWeight: 200,
                        }}
                      >
                        Provider Website
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 200,
                        }}
                      >
                        {iter.provider.website}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            );
          })
        ) : (
          <div
            style={{
              textAlign: "center",
              width: "100%",
              padding: "15px",
              marginTop: "10px",
            }}
          >
            No Entries Available
          </div>
        )}
      </Grid>
    );
  };

  return (
    <div style={{ padding: "5px" }}>
      <Fab
        size="small"
        aria-label="add"
        style={{
          borderRadius: "25px",
          backgroundColor: "#ee17ee",
          color: "white",
          position: "absolute",
          top: "80%",
          left: "90%",
          cursor: "pointer",
          zIndex: 99999999,
        }}
        onClick={() => {
          setModal({
            open: true,
            details: null,
          });
        }}
      >
        <AddIcon />
      </Fab>
      <div>
        <ArrowBackIcon
          style={{
            color: "white",
            fontSize: "30px",
            margin: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            props.reset();
          }}
        />{" "}
        <span
          style={{
            fontSize: "25px",
            color: "white",
            position: "relative",
            top: "-15px",
          }}
        >
          {" "}
          Book a Utility
        </span>
      </div>
      <div style={{ margin: "0px 30px 0px 50px" }}>
        {Object.keys(utilityList).map((iter, index) => {
          return (
            <Accordion
              expanded={expanded === index}
              onChange={handleChange(index)}
              style={{
                borderRadius:
                  index == 0
                    ? "10px 10px 0px 0px"
                    : index == Object.keys(utilityList).length - 1
                    ? "0px 0px 10px 10px"
                    : "",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  {iter}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {accordianDetailsFun(utilityList[iter], iter)}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
      {modalFun()}
    </div>
  );
};

export default Utilities;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import "./index.scss";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import ListRoundedIcon from "@mui/icons-material/ListRounded";
const Menu = [
  {
    menu: "home",
    subMenu: [
      {
        name: "Home",
        icon: <HomeRoundedIcon />,
        id: 1,
        path: "/home",
        array: [
          {
            name: "Moving",
            id: 11,
            path: "/moving",
          },
          {
            name: "Senior Transitions",
            id: 12,
            path: "/senior-transitioning",
          },
          {
            name: "Renovation",
            id: 13,
            path: "/renovation",
          },
          {
            name: "Other Service",
            id: 14,
            path: "/other-service",
          },
        ],
      },
      {
        name: "Profile",
        icon: <PersonRoundedIcon />,
        id: 2,
        path: "/profile",
      },
      {
        name: "More",
        icon: <ListRoundedIcon />,
        id: 3,
        path: "/more",
        array: [
          // {
          //   name: "System Status",
          //   id: 31,
          //   path: "/system-status",
          // },
          // {
          //   name: "Kernel Logs",
          //   id: 32,
          //   path: "/kernel-logs",
          // },
          // {
          //   name: "Processes",
          //   id: 33,
          //   path: "/processes",
          // },
          // {
          //   name: "Real Time Graphs",
          //   id: 34,
          //   path: "/real-time-graphs",
          // },
        ],
      },
      // {
      //   name: "Remote Access",
      //   icon: (
      //     <img
      //       src={RemoteAccessIcon}
      //       alt="Remote Accesss"
      //       style={{
      //         width: "20px",
      //         height: "35px",
      //       }}
      //     />
      //   ),
      //   id: 4,
      //   path: "/rempte-access",
      // },
      // {
      //   name: "Updates",
      //   icon: (
      //     <img
      //       src={UpdateIcon}
      //       alt="Updates"
      //       style={{
      //         width: "25px",
      //         height: "20px",
      //       }}
      //     />
      //   ),
      //   id: 5,
      //   path: "/updates",
      //   array: [
      //     {
      //       name: "Rollout",
      //       id: 51,
      //       path: "/rollout",
      //     },
      //     {
      //       name: "Distributions",
      //       id: 52,
      //       path: "/distributions",
      //     },
      //   ],
      // },
      // {
      //   name: "Setting",
      //   icon: <SettingsIcon />,
      //   id: 6,
      //   path: "/setting",
      // },
    ],
  },
];
const SideMenu = (props) => {
  const history = useNavigate();
  const [activeMenu, setActiveMenu] = useState(1);
  const [menuName, setMenuName] = useState("Home");
  const [open, setOpen] = useState([]);
  const handleClick = (path, id, name) => {
    setActiveMenu(id);
    setMenuName(name);
    history(path);
  };

  // useEffect(() => {
  //   props.headerName(menuName);
  // }, [activeMenu]);

  useEffect(() => {
    let menuString = props.activeMenu.toString();
    if (menuString.length > 1) {
      let opList = [...open];
      if (!opList.includes(parseInt(menuString.charAt(0)))) {
        opList.push(parseInt(menuString.charAt(0)));
      }
      setOpen(opList);
      let subMenu = Menu[0].subMenu[
        parseInt(menuString.charAt(0)) - 1
      ].array.filter((e) => e.id == props.activeMenu);
      setMenuName(subMenu[0].name);
    }
    setActiveMenu(props.activeMenu);
  }, [props.activeMenu]);

  const openFun = (id) => {
    let opList = [...open];
    if (opList.includes(id)) {
      opList.splice(opList.indexOf(id), 1);
    } else {
      opList.push(id);
    }
    setOpen(opList);
  };

  const menuFun = (menu) => {
    let arrayList = [];
    menu.subMenu.forEach((list) => {
      arrayList.push(
        <ListItem
          key={list.name}
          button
          onClick={() =>
            list.array
              ? openFun(list.id)
              : handleClick(list.path, list.id, list.name)
          }
          selected={list.id == activeMenu}
        >
          <ListItemIcon style={{ color: "white" }}>{list.icon}</ListItemIcon>
          {true && (
            <ListItemText
              className={"responsive"}
              style={{ color: "#fff" }}
              primary={list.name}
            />
          )}
        </ListItem>
      );
      let temp = [];
      list.array?.forEach((inner) => {
        temp.push(
          <ListItem
            key={inner.name}
            button
            onClick={() => handleClick(inner.path, inner.id, inner.name)}
            selected={inner.id == activeMenu}
            className={"responsive"}
            style={{
              borderRadius: "20px",
              backgroundColor: inner.id == activeMenu ? "#0fcbd0" : "",
              display: open.includes(list.id) ? "" : "none",
              marginLeft: "12px",
              position: "relative",
              top: "5px",
              width: "120px",
            }}
          >
            {true && (
              <ListItemText
                className={"responsive"}
                // primary={inner.name}
              >
                <div
                  style={{
                    color: "#fff",
                    fontSize: "10px",
                  }}
                >
                  {inner.name}
                </div>
              </ListItemText>
            )}
          </ListItem>
        );
      });
      if (list.array?.length > 0)
        arrayList.push(
          <div
            className={"responsive"}
            style={{
              //  paddingBottom: "10px",
              backgroundColor: "#085e6f",
              marginLeft: "20px",
              marginRight: "20px",
              borderRadius: "10px",
              paddingBottom: "10px",
              // height: "170px",
              display: open.includes(list.id) ? "" : "none",
            }}
          >
            {temp}
          </div>
        );
    });
    return arrayList;
  };

  return (
    <div>
      {Menu.map((menu) => {
        return (
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            key={"menu" + menu.id}
            subheader={
              false ? (
                <ListSubheader
                  style={{ color: "gray" }}
                  component="div"
                  id="nested-list-subheader"
                >
                  {menu.name}
                </ListSubheader>
              ) : null
            }
          >
            {menuFun(menu)}
          </List>
        );
      })}
    </div>
  );
};
export default SideMenu;

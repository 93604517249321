import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  API_URL,
  GET_USER_INSURANCES,
  UPLOAD,
  CREATE_INSURANCE,
  DELETE_INSURANCE,
  UPDATE_INSURANCE,
} from "../components/api/constant";
import { invokeApi, invokeUploadFile } from "./api/index";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { TextField, Button } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import upload from "../resources/icons/upload.png";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Insurance = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  const [insuranceList, setInsuranceList] = useState([]);
  const [modal, setModal] = React.useState({
    open: false,
    details: null,
  });
  const [loading, setLoading] = useState(false);
  const loc = useLocation();
  const uploadRef = useRef(null);

  const fileInputChange = (e, bool) => {
    let array = [];
    let formData = new FormData();
    formData.append("upload", e.target.files[0]);
    setLoading(true);
    invokeUploadFile(`${API_URL}${UPLOAD}?needTags=false`, formData)
      .then((response) => {
        array.push({
          name: response.data.name,
          url: response.data.location,
        });
        setModal({
          ...modal,
          details: { ...modal.details, insuranceFiles: array },
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Upload failed err", err);
      });
  };

  const modalNameFun = (val) => {
    switch (val) {
      case 0:
        return "Vehicle";
      case 1:
        return "Pet";
      case 2:
        return "Home";
      default:
        return "Other";
    }
  };

  const modalFun = () => {
    return (
      <Modal
        disableEnforceFocus={true}
        open={modal.open == true ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setModal({
              open: false,
              details: null,
            });
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Backdrop sx={{ color: "#fff", zIndex: 9999999 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box
            style={{
              p: 4,
              position: "absolute",
              top: "50%",
              left: "50%",
              padding: "20px",
              width: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#935252",
              border: "none",
              borderRadius: "20px",
            }}
          >
            <div
              style={{
                textAlign: "center",
                padding: "0px 0px 20px 0px",
                fontSize: "25px",
              }}
            >
              {modal.details?.id == undefined
                ? "Create " + modalNameFun(expanded) + " Insurance"
                : "Edit " + modal.details?.insuranceType + " Insurance"}
            </div>
            <Grid container spacing={5} style={{ marginLeft: "-20px" }}>
              <Grid item xs={12} md={3}>
                <div>Reg No</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="regNo"
                  className={
                    modal.details?.regNo < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: { ...modal.details, regNo: e.target.value },
                    });
                  }}
                  color="primary"
                  placeholder="Reg no."
                  value={modal.details?.regNo}
                  variant="standard"
                  style={{ width: "75%" }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <div>Cost</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="cost"
                  className={
                    modal.details?.cost < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: { ...modal.details, cost: e.target.value },
                    });
                  }}
                  color="primary"
                  placeholder="Cost"
                  value={modal.details?.cost}
                  style={{ width: "75%" }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <div>Insurance Comprehensive</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="insuranceComprehensive"
                  className={
                    modal.details?.insuranceComprehensive < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: {
                        ...modal.details,
                        insuranceComprehensive: e.target.value,
                      },
                    });
                  }}
                  color="primary"
                  placeholder="Insurance Compprehensive"
                  value={modal.details?.insuranceComprehensive}
                  style={{ width: "75%" }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <div>Location</div>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="location"
                  className={
                    modal.details?.location < 1
                      ? "createGroupFieldsError"
                      : "createGroupFields"
                  }
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      details: { ...modal.details, location: e.target.value },
                    });
                  }}
                  color="primary"
                  placeholder="Location"
                  value={modal.details?.location}
                  style={{ width: "75%" }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <div
                  onClick={() => {
                    uploadRef.current?.click();
                  }}
                  style={{ display: "flex", cursor: "pointer" }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "auto",
                      marginRight: "50px",
                    }}
                  >
                    <input
                      ref={uploadRef}
                      id="upload-video"
                      type="file"
                      onChange={(e) => {
                        fileInputChange(e, true);
                      }}
                      style={{ display: "none" }}
                    />
                    <span
                      style={{
                        fontSize: "12px",
                        padding: "3px",
                        fontWeight: 300,
                        color: "white",
                      }}
                    >
                      Upload Insurance File
                    </span>
                    <img
                      src={upload}
                      alt="upload"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                        marginLeft: "5px",
                        color: "white",
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  {modal.details?.insuranceFiles?.map((iter) => {
                    return (
                      <div
                        style={{
                          fontSize: "12px",
                          color: "white",
                          marginLeft: "auto",
                          marginRight: "50px",
                          fontWeight: 300,
                          padding: "5px 30px 5px 5px",
                        }}
                      >
                        {iter.name}
                      </div>
                    );
                  })}
                </div>
              </Grid>
            </Grid>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  margin: "10px",
                }}
                onClick={() => {
                  let list = insuranceList;
                  let serviceId = loc.state.id;
                  let payload = {
                    policyDoc:
                      modal.details.insuranceFiles[0].url == ""
                        ? "NA"
                        : modal.details.insuranceFiles[0].url,
                    insuranceType: modalNameFun(expanded),
                    details: {
                      regNo: modal.details.regNo,
                      cost: modal.details.cost,
                      insuranceComprehensive:
                        modal.details.insuranceComprehensive,
                      location: modal.details.location,
                    },
                  };

                  if (modal.details.id == undefined) {
                    setLoading(true);
                    invokeApi(
                      "POST",
                      `${API_URL}${CREATE_INSURANCE.replace(
                        "{serviceId}",
                        serviceId
                      )}`,
                      payload
                    )
                      .then((res) => {
                        if (res.id) {
                          list.push(res);
                          setInsuranceList(list);
                          setModal({
                            open: false,
                            details: null,
                          });
                          toast.success("Insurance Creation Successful");
                        }
                        setLoading(false);
                      })
                      .catch((err) => {
                        setLoading(false);
                        toast.error("Insurance creation failed");
                      });
                  } else {
                    setLoading(false);
                    invokeApi(
                      "PUT",
                      `${API_URL}${UPDATE_INSURANCE.replace(
                        "{serviceId}",
                        serviceId
                      )}` + modal.details.id,
                      payload
                    )
                      .then((res) => {
                        if (res.id) {
                          let updatedList = list.map((iter) => {
                            if (iter.id == res.id) {
                              return res;
                            } else {
                              return iter;
                            }
                          });
                          setInsuranceList(updatedList);
                          setModal({
                            open: false,
                            details: null,
                          });
                          toast.success("Insurance updation Successful");
                        }
                        setLoading(false);
                      })
                      .catch((err) => {
                        toast.error("Insurance updation failed");
                        setLoading(false);
                      });
                  }
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  margin: "10px",
                  display: modal.details?.id == undefined ? "none" : "",
                }}
                onClick={() => {
                  setLoading(true);
                  invokeApi(
                    "DELETE",
                    `${API_URL}${DELETE_INSURANCE}` + modal.details.id
                  )
                    .then((res) => {
                      if (res.id) {
                        let updatedList = insuranceList.filter((iter) => {
                          return iter.id != res.id;
                        });
                        setInsuranceList(updatedList);
                        setModal({
                          open: false,
                          details: null,
                        });
                        toast.success("Insurance Deletion Successful");
                      }
                      setLoading(false);
                    })
                    .catch((err) => {
                      toast.error("Insurance Deletion failed");
                      setLoading(false);
                    });
                }}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  margin: "10px",
                }}
                onClick={() => {
                  setModal({
                    open: false,
                    details: null,
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </>
      </Modal>
    );
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    invokeApi("GET", `${API_URL}${GET_USER_INSURANCES}`)
      .then((response) => {
        setInsuranceList(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const accordianDetailsFun = (val, name) => {
    let result = [];
    val.forEach((iter, pos) => {
      if (iter.insuranceType == name) {
        iter["index"] = pos;
        result.push(iter);
      }
    });
    return (
      <Grid container spacing={2}>
        {result.length > 0 ? (
          result.map((iter) => {
            return (
              <Grid item xs={12} md={6}>
                <div
                  style={{
                    backgroundColor: "#e3e0df",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setModal({
                        open: true,
                        details: {
                          cost: iter.details.cost,
                          insuranceComprehensive:
                            iter.details.insuranceComprehensive,
                          location: iter.details.location,
                          regNo: iter.details.regNo,
                          insuranceType: iter.insuranceType,
                          id: iter.id,
                          insuranceFiles: [
                            {
                              name:
                                iter.policyDoc.indexOf("https://") > -1
                                  ? iter.policyDoc.split("/")[
                                      iter.policyDoc.split("/").length - 1
                                    ]
                                  : "NA",
                              url: iter.policyDoc,
                            },
                          ],
                        },
                      });
                    }}
                  >
                    <Grid item xs={4}>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          fontWeight: 200,
                        }}
                      >
                        Reg No
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 200,
                        }}
                      >
                        {iter.details.regNo}
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          fontWeight: 200,
                        }}
                      >
                        Cost
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 200,
                        }}
                      >
                        {iter.details.cost}
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          fontWeight: 200,
                        }}
                      >
                        Location
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 200,
                        }}
                      >
                        {iter.details.location}
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          fontWeight: 200,
                        }}
                      >
                        Insurance Comprehensive
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 200,
                        }}
                      >
                        {iter.details.insuranceComprehensive}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            );
          })
        ) : (
          <div
            style={{
              textAlign: "center",
              width: "100%",
              padding: "15px",
              marginTop: "10px",
            }}
          >
            No Entries Available
          </div>
        )}
      </Grid>
    );
  };

  return (
    <div style={{ padding: "5px" }}>
      <Fab
        size="small"
        aria-label="add"
        style={{
          borderRadius: "25px",
          backgroundColor: "#ee17ee",
          color: "white",
          position: "absolute",
          top: "80%",
          left: "90%",
          cursor: expanded === false ? "not-allowed" : "pointer",
          zIndex: 99999999,
        }}
        onClick={() => {
          if (expanded !== false) {
            setModal({
              open: true,
              details: {
                insuranceFiles: [
                  {
                    name: "",
                    url: "",
                  },
                ],
              },
            });
          }
        }}
      >
        <AddIcon />
      </Fab>
      <div>
        <ArrowBackIcon
          style={{
            color: "white",
            fontSize: "30px",
            margin: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            props.reset();
          }}
        />{" "}
        <span
          style={{
            fontSize: "25px",
            color: "white",
            position: "relative",
            top: "-15px",
          }}
        >
          {" "}
          Insurance
        </span>
      </div>
      <div style={{ margin: "0px 30px 0px 50px" }}>
        <Accordion
          expanded={expanded === 0}
          onChange={handleChange(0)}
          style={{ borderRadius: "10px 10px 0px 0px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Vehicle
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {accordianDetailsFun(insuranceList, "Vehicle")}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 1} onChange={handleChange(1)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Pet</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {accordianDetailsFun(insuranceList, "Pet")}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 2} onChange={handleChange(2)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Home</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {accordianDetailsFun(insuranceList, "Home")}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 3}
          style={{ borderRadius: "0px 0px 10px 10px" }}
          onChange={handleChange(3)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Other</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {accordianDetailsFun(insuranceList, "Other")}
          </AccordionDetails>
        </Accordion>
      </div>
      {modalFun()}
    </div>
  );
};

export default Insurance;

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../components/login";
import ForgotPassword from "../components/login/ForgetPassword";
import { LandingPage } from "./LandingPage";
import AuthRoute from "./AuthRoute";

const AppRoutes = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route exact path="*" element={<AuthRoute />}>
            <Route path="*" element={<LandingPage />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;

import React from "react";
import "./index.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "./routes";
import Loader from "./components/core/loader";
import "./App.css";

function App() {
  return (
    <div className="root">
      <Loader />
      <ToastContainer
        position="top-center"
        hideProgressBar
        newestOnTop={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <AppRoutes />
    </div>
  );
}

export default App;
